/*
Login - A login page with a splash screen.

A Login page consists of InputFieldBlocks, an InputCheckbox, and a SignUp components.
*/

import React from 'react';
import { Link } from 'react-router-dom';

// utils
import config from '../config.js';
import { forgotPassword, login } from '@api/auth';
import { toast_error, toast_success } from '@libs/toast-wrappers';
import { useUserProfile } from '@states/user-profile';

import {
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  ERROR_INVALID_LOGIN_CREDENTIALS,
  ERROR_LOGIN_REQUEST,
  ERROR_SENDING_RESET_PASSWORD_EMAIL,
  SUCCESS_SENDING_RESET_PASSWORD_EMAIL,
} from '@utils/messages';
import { MAIN_ROUTE_BY_ROLE, ROUTES } from '@router/routes';
import { router } from '@router/root';

//internationalization and translation support
import i18n from '@libs/i18n.js';
import { withTranslation, Trans } from 'react-i18next';

// assets
import logo from '../assets/images/vitazi-logo-transparent.png';

// components
import InputCheckbox from '../lib-medical-portal/components/FormInputs/InputCheckbox.jsx';
import InputFieldBlock from '../lib-medical-portal/components/FormInputs/InputFieldBlock.jsx';
import SignUp from './SignUp';
import { Copyright } from './footer/copyright.jsx';
import { AuthSplash } from './auth/splash';

const ENV = import.meta.env;
const useAPIGateway = ENV.VITE_USE_API_GATEWAY === 'true';

/*
state:
	pageState: holds the state of the Login page to show an appropriate form.

props (component-level arguments):
	A Login Page has no props.
*/
const mStateLogin = 'login';
const mStateSignup = 'signUp';
const mStateForgotPassword = 'forgotPassword';
const mStateFormSubmitted = 'formSubmitted';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageState: mStateLogin,
    };
    this.updateLayout = this.updateLayout.bind(this);
    this.showLoginForm = this.showLoginForm.bind(this);
    this.showForgotPasswordForm = this.showForgotPasswordForm.bind(this);
    this.showFormSubmittedPage = this.showFormSubmittedPage.bind(this);
    this.onClickPassResetButton = this.onClickPassResetButton.bind(this);
  }

  updateLayout(state) {
    this.setState({
      pageState: state,
    });
  }

  // Called when a user clicks on 'Login here.' button from the Sign up screen.
  showLoginForm() {
    this.updateLayout(mStateLogin);
  }

  showSignUpForm() {
    this.updateLayout(mStateSignup);
  }

  showForgotPasswordForm() {
    this.updateLayout(mStateForgotPassword);
  }

  showFormSubmittedPage() {
    this.updateLayout(mStateFormSubmitted);
  }

  // Called when a user clicks on 'Login' button to log in from the Login screen.
  onClickLoginButton(context) {
    return function (event) {
      event.preventDefault();
      context.submitLoginRequest(context);
      return false;
    };
  }

  onClickPassResetButton() {
    return (event) => {
      event.preventDefault();

      void forgotPassword(
        document.getElementById('email').value,
        window.location.protocol + '//' + window.location.host + ROUTES.CHANGE_PASSWORD
      ).then((response) => {
        if (response) {
          console.log('success forgotPassword');
          toast_success(SUCCESS_SENDING_RESET_PASSWORD_EMAIL);
          setTimeout(() => {
            this.showLoginForm();
          }, 5000);
        }
      });
      return false;
    };
  }

  submitLoginRequest() {
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    login(email, password).then((data) => {
      if (useAPIGateway) {
        if (!data['Session Name']) {
          toast_error(ERROR_INVALID_LOGIN_CREDENTIALS);
          return false;
        }

        // set AWS auth related cookies
        document.cookie = 'aws-access-key=' + data['Access Key'] + '; path=/;';
        document.cookie = 'aws-secret-key=' + data['Secret Key'] + '; path=/;';
        document.cookie = 'aws-security-token=' + data['Security Token'] + '; path=/;';
        // set visionquest-specific cookies
        document.cookie = 'visionquest-session-name=' + data['Session Name'] + '; path=/;';
        let session_components = data['Session Name'].split('_');
        document.cookie = 'visionquest-user-role=' + session_components[0] + '; path=/;';
        document.cookie = 'visionquest-user-clinic=' + session_components[1] + '; path=/;';
        document.cookie = 'visionquest-user-email=' + session_components[2] + '; path=/;';
        document.cookie = 'visionquest-clinic-name=' + data['Clinic Name'] + '; path=/;';

        // set the user state
        useUserProfile.getState().setEmail(email);
        useUserProfile.getState().setClinic(session_components[1]);
        useUserProfile.getState().setRole(session_components[0]);
      } else {
        useUserProfile.getState().setAuthenticationData(data.token);
      }

      const from = new URLSearchParams(window.location.search).get('from');
      if (from && from !== ROUTES.LOGOUT) {
        router.navigate(from);
      } else {
        router.navigate(MAIN_ROUTE_BY_ROLE[useUserProfile.getState().profile.role]);
      }
    });
  }
  // The main render function has become too large and hard to parse. Split up the loginform rendering into its own function
  renderLoginForm() {
    return (
      <form className="login-form" onSubmit={this.onClickLoginButton(this)}>
        <InputFieldBlock
          title={i18n.t('login-email')}
          fieldName="email"
          titleClass="login-email-label"
          fieldClass="login-email-input"
          fieldType="email"
          fieldHint=""
          fieldRequired={true}
        />
        <InputFieldBlock
          title={i18n.t('login-password')}
          fieldName="password"
          titleClass="login-password-label"
          fieldClass="login-password-input"
          fieldHint="****"
          fieldType="password"
          fieldRequired={true}
        />
        <div className="login-forgot-pass-link" onClick={() => this.showForgotPasswordForm()}>
          {i18n.t('login-forgot-pass')}
        </div>
        <InputCheckbox inputId="login-terms-accept" desc={i18n.t('login-terms')} required />
        <div className="login-button-row">
          <button type="submit" className="button">
            {i18n.t('login-login')}
          </button>
          <div className="wrapper-new-user-sign-up">
            <p className="signup-cta inline-block-mobile-margin">{i18n.t('login-new-user')}</p>
            <Trans i18nKey="login-signup-link" className="inline-block">
              <p
                className="inline-block-mobile-margin login-page-blue-clickable-text"
                onClick={() => this.showSignUpForm()}
              >
                Sign-up here.
              </p>
            </Trans>
          </div>
        </div>
      </form>
    );
  }

  renderForgotPasswordForm() {
    return (
      <div className="login-form-row">
        <img className="logo" src={logo} alt="Vitazi.ai Logo" />
        <div className="page-title">{i18n.t('login-reset-pass')}</div>
        <Trans i18nKey="login-reset-pass-instructions">
          <p>
            Please enter your email to reset your password. A password reset link will be emailed to
            you with instructions on how to reset your password.
          </p>
        </Trans>
        <form className="login-form" onSubmit={this.onClickPassResetButton(this)}>
          <InputFieldBlock
            title={i18n.t('login-email')}
            fieldName="email"
            titleClass="login-email-label"
            fieldClass="login-email-input"
            fieldType="email"
            fieldRequired={true}
          />
          <div className="login-forgot-pass-link" onClick={() => this.showLoginForm()}>
            {i18n.t('login-remembered-pass')}
          </div>
          <Link className="login-forgot-pass-link" to={ROUTES.CHANGE_PASSWORD}>
            {i18n.t('login-change-pass-question')}
          </Link>
          <InputCheckbox inputId="login-terms-accept" desc={i18n.t('login-terms')} required />
          <div className="login-button-row">
            <button type="submit" className="button">
              {i18n.t('login-submit-pass-reset')}
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="Login">
        <div className="grid-container full">
          <div className="grid-x">
            <AuthSplash />

            {this.state.pageState === mStateLogin && (
              <div className="cell small-12 large-6 large-offset-1 form-right-panel">
                <div className="login-form-row">
                  <img className="logo" src={logo} alt="Vitazi.ai Logo" />
                  <Trans i18nKey="login-login-title">
                    <div className="page-title">
                      Login to the{' '}
                      <a className="footer-link" href="https://www.vitazi.ai/">
                        Vitazi.ai
                      </a>{' '}
                      dashboard
                    </div>
                  </Trans>
                  {this.renderLoginForm()}
                </div>
                <Copyright />
              </div>
            )}

            {this.state.pageState === mStateSignup && (
              <div className="cell small-12 large-6 large-offset-1 form-right-panel">
                <SignUp
                  callbackFormSubmittedPage={this.showFormSubmittedPage}
                  callbackLoginPage={this.showLoginForm}
                />
                <Copyright />
              </div>
            )}

            {this.state.pageState === mStateForgotPassword && (
              <div className="cell small-12 large-6 large-offset-1 form-right-panel">
                {this.renderForgotPasswordForm()}
                <Copyright />
              </div>
            )}

            {this.state.pageState === mStateFormSubmitted && (
              <div className="cell small-12 large-6 large-offset-1 form-right-panel">
                <div className="form-submitted-view">
                  <div className="page-title">
                    Thank you! Our staff is reviewing your request. Please check your email for a
                    verification code to complete registration.
                  </div>
                  <button className="button" onClick={() => this.showLoginForm()}>
                    Back To Login
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
