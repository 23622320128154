import { errorlog } from '@libs/error-logs';
import { toast_error } from '@libs/toast-wrappers';
import { GET } from './base/index';
import { ENDPOINTS } from './endpoints';
import { ERROR_LEGAL_DOCUMENT_DOWNLOAD } from '@utils/messages';

export async function getLegalDoc(filePath: string) {
  try {
    const response = await GET<File>(ENDPOINTS.LEGAL_DOC_FILE, {
      parameters: { filePath },
      isFileRequest: true,
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/legal/getLegalDoc', 'Error fetching legal document');
    toast_error(ERROR_LEGAL_DOCUMENT_DOWNLOAD);
    return Promise.reject(error);
  }
}

export async function getLegalDocsList() {
  try {
    const response = await GET<{ documents: unknown[] }>(ENDPOINTS.LEGAL_DOCS);
    return response.documents;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/legal/getLegalDocsList',
      'Error fetching legal documents list'
    );
    toast_error(ERROR_LEGAL_DOCUMENT_DOWNLOAD);
    return Promise.reject(error);
  }
}
