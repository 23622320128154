import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Case, getCaseImage } from '@api/cases';
import { signGradeCase } from '@api/grade';
import { SignGradeForm, defaultSignGradeForm } from '@hooks/use-grade-form-logic';
import { useUserProfile } from '@states/user-profile';
import { decisionStrToBool, decisionBoolToStr } from '@utils/strings';
import { toast_error, toast_success } from '@libs/toast-wrappers';
import { errorlog } from '@libs/error-logs';

import { FetchStates } from '@components/fetch-states';
import { Signature } from '@components/signature';
import { Hr } from '@components/divider';
import { GradeCaseImages } from './grade-case-images';
import { GradeFormMainQuestions } from './grade-form-main-questions';
import { GradeFormUrgentConfirm } from './grade-form-urgent-confirm';

import { ERROR_GRADING_CASE, ERROR_MISSING_SIGNATURE, SUCCESS_GRADING_CASE } from '@utils/messages';
import { ROUTES } from '@router/routes';

type ImagesList = { path: string; file: File }[];

type LoaderData = { caseImgs: string[] };

export function GradeCase() {
  const [saving, setSaving] = useState(false);
  const [images, setImages] = useState<ImagesList>([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);

  const { register, watch, handleSubmit, setValue, reset } = useForm<SignGradeForm>({
    defaultValues: defaultSignGradeForm,
  });

  const { caseId } = useParams();
  const { email } = useUserProfile((state) => ({ email: state.profile.email ?? '' }));
  const { caseImgs: imagesUrls } = useLoaderData() as LoaderData;

  const navigation = useNavigation();
  const navigate = useNavigate();
  const location: { state?: { case?: Case } } = useLocation();
  const { t } = useTranslation();

  const onSignatureChange = useCallback((data: string) => {
    setSignature(data);
  }, []);

  const handleResetForm = useCallback(() => {
    reset();
  }, [reset]);

  const currentGradeCase = useMemo(() => {
    if (location.state) {
      const selectedCase = location.state?.case;
      if (!selectedCase?.grades) return undefined;

      return selectedCase.grades.sort((a, b) => {
        // from createdUtc = "2024-07-17 18:50:57.981410+00:00"
        return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
      })[0];
    }
  }, [location.state]);

  const disabled = Boolean(currentGradeCase) || saving;

  useEffect(() => {
    if (images.length) return;

    void (async () => {
      if (imagesUrls.length > 0) {
        setLoadingImages(true);
        const urls = imagesUrls.filter((url) => !url.includes('.zip'));
        const response = await Promise.all(urls.map(getCaseImage));
        if (response) {
          // const images = response.filter((img) => img instanceof Blob) as File[];
          const images = response.filter((response) => response.file instanceof Blob);
          if (images.length) {
            const readeable = images.map((img) => ({ img: img.path, isReadable: '' }));
            setImages(images);
            if (!currentGradeCase?.case_id) {
              setValue('imagesReadable_string', JSON.stringify(readeable));
            }
          }
        }
        setLoadingImages(false);
      }
    })();
  }, [imagesUrls, setValue, images.length]);

  useEffect(() => {
    if (navigation.state === 'loading' && images.length) {
      setImages([]);
    }
  }, [navigation.state, images]);

  useEffect(() => {
    // preload grade
    if (currentGradeCase) {
      console.log('currentGradeCase', currentGradeCase);
      Object.entries(currentGradeCase).forEach(([key, value]) => {
        const originKey = key as keyof SignGradeForm;
        const isBoolean = originKey.includes('bool');
        const formKey = isBoolean
          ? (originKey.replace('bool', 'str') as keyof SignGradeForm)
          : originKey;
        const formValue = isBoolean ? decisionBoolToStr(value as boolean) : value;
        setValue(formKey, formValue);
      });
    }
  }, [currentGradeCase?.case_id, setValue]);

  const onSubmitGradeCase: SubmitHandler<SignGradeForm> = async (form) => {
    try {
      if (!caseId || !email) return;

      // get the signature data
      if (!signature) {
        toast_error(ERROR_MISSING_SIGNATURE);
        return;
      }

      setSaving(true);

      const {
        readable_str,
        evidenceOfDme_str,
        otherDiseaseCustomInput_string,
        otherDiseaseCustom_bool,
        otherDiseaseNone_bool,
        urgent_str,
        ...rest_form
      } = form;

      // get the other disease custom string
      let otherDiseaseCustom_string = '';
      if (otherDiseaseNone_bool) {
        otherDiseaseCustom_string = 'None';
      } else if (otherDiseaseCustom_bool) {
        otherDiseaseCustom_string = otherDiseaseCustomInput_string;
      }

      // validate if at least 2 criteria from 47 are selected
      const twoCriteriaFrom47_bool =
        [
          rest_form.moderateRhsPlusMildIrma_bool,
          rest_form.mildIrmaIn4Quadrants_bool,
          rest_form.severeRhIn2To3Quadrants_bool,
          rest_form.venousBeadingIn1Quadrant_bool,
        ].filter(Boolean).length >= 2;

      // send the form case to the server
      await signGradeCase({
        ...rest_form,
        case_id: caseId,
        user_email: email,
        signatureDataUri_string: signature,
        readable_bool: decisionStrToBool(readable_str),
        evidenceOfDme_bool: decisionStrToBool(evidenceOfDme_str),
        twoCriteriaFrom47_bool: twoCriteriaFrom47_bool,
        otherDiseaseCustom_string,
        urgent_bool: urgent_str ? decisionStrToBool(urgent_str) : false,
      });

      toast_success(SUCCESS_GRADING_CASE);
      navigate(ROUTES.GRADING);
    } catch (error) {
      errorlog(error as Error, 'src/components/grading/grade-case', 'Error signing case');
      toast_error(ERROR_GRADING_CASE);
    }
    setSaving(false);
  };

  return (
    <FetchStates loading={navigation.state === 'loading' || loadingImages}>
      <section className="page-content fade">
        <GradeCaseImages images={images} setFormValue={setValue} watch={watch} />
        <form onSubmit={handleSubmit(onSubmitGradeCase)}>
          {/* Main questions of the form */}
          <GradeFormMainQuestions
            register={register}
            watch={watch}
            setValue={setValue}
            disabled={disabled}
          />
          {/* urgent follow up */}
          <GradeFormUrgentConfirm
            register={register}
            watch={watch}
            setValue={setValue}
            disabled={disabled}
          />
          <Hr className="col-span-2" />
          <Signature
            onChange={onSignatureChange}
            initialSignature={currentGradeCase?.signatureDataUri_string}
            disabled={disabled}
          />

          <div className="col-start-1 col-span-2 mt-4 flex justify-between">
            <button
              type="button"
              disabled={disabled}
              className={disabled ? 'cursor-not-allowed' : ''}
              onClick={handleResetForm}
            >
              <span className="mr-2">
                <FontAwesomeIcon icon={faTrashCan} />
              </span>
              {t('clear-form')}
            </button>
            <button
              type={currentGradeCase ? 'button' : 'submit'}
              className="button"
              disabled={disabled}
            >
              {saving ? `... ${t('Processing')}` : t('submit')}
            </button>
          </div>
        </form>
      </section>
    </FetchStates>
  );
}
