import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserProfile } from '@states/user-profile';
import { devlog } from '@libs/dev-logs';
import { getSessionToken } from '@libs/session-management';
import { parse_cookies } from '../react-utils/src/libformat';
import { MAIN_ROUTE_BY_ROLE, RESTRICTED_ROUTES, ROUTE_BY_ROLE, ROUTES } from '@router/routes';

const ENV = import.meta.env;
const useAPIGateway = ENV.VITE_USE_API_GATEWAY === 'true';

// this hooks will subscribe to the navigation changes
export function useNavigationListener() {
  const location = useLocation();
  const navigate = useNavigate();
  const { profile, setEmail, setClinic, setRole, setAuthenticationData } = useUserProfile();

  // we need to load the user email when refresh the page and the cookie exists
  useEffect(() => {
    if (useAPIGateway) {
      const cookies = parse_cookies();
      const hasLoginCookies = cookies?.['visionquest-user-email'] && cookies?.['aws-access-key'];

      if (hasLoginCookies && cookies['visionquest-user-email'] && !profile.email) {
        devlog('useNavigationListener: setting data from cookies');
        setEmail(cookies['visionquest-user-email'] as string);
        setClinic(cookies['visionquest-user-clinic'] as string);
        setRole(cookies['visionquest-user-role'] as string);
      }
    } else {
      const token = getSessionToken();

      if (token && !profile?.email) {
        devlog('useNavigationListener: data from token session');
        setAuthenticationData(token);
      }
    }
  }, [location, profile.email, setEmail, setClinic, setRole, setAuthenticationData]);

  // we need to redirect users based on the role if needed
  useEffect(() => {
    if (profile.email && profile.role) {
      const allowed = RESTRICTED_ROUTES[profile.role as ROUTE_BY_ROLE];
      if (!allowed) return;

      // for grades we could have subroutes so we handle it separately
      if (location.pathname.includes(ROUTES.GRADING) && allowed.includes(ROUTES.GRADING)) return;

      const isRestricted = !allowed.includes(location.pathname);
      if (isRestricted) {
        devlog('useNavigationListener: redirecting user to main route');
        // we need to redirect the user to the main route based on the role
        navigate(MAIN_ROUTE_BY_ROLE[profile.role as ROUTE_BY_ROLE]);
      }
    }
  }, [location, navigate, profile.email, profile.role]);
}
