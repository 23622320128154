import { getSessionToken } from '@libs/session-management';
import { parse_cookies } from '../react-utils/src/libformat';

const ENV = import.meta.env;
const useAPIGateway = ENV.VITE_USE_API_GATEWAY === 'true';

export const authValidation = () => {
  if (useAPIGateway) {
    const cookies = parse_cookies();
    return Object.prototype.hasOwnProperty.call(cookies, 'visionquest-session-name');
  }
  return Boolean(getSessionToken());
};
