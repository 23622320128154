import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getGradeCases, Case } from '@api/cases';
import { useUserProfile } from '@states/user-profile';
import { parseDateString } from '@utils/dates';

type CaseFilters = {
  graded?: boolean;
};

export const useGetGradingCases = () => {
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState<Case[]>([]);
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);
  const [filter, serFilter] = useState<CaseFilters>({});
  const { caseId: caseSelectedId } = useParams();

  const { email } = useUserProfile((state) => ({
    email: state.profile.email,
    clinic: state.profile.clinic,
  }));

  const filterByGradedCases = useCallback(() => {
    const newFilter = { ...filter, graded: !filter.graded };
    serFilter(newFilter);

    if (newFilter.graded) {
      setFilteredCases(cases.filter((c) => c.grades !== undefined && c.grades.length > 0));
    } else {
      setFilteredCases(cases);
    }
  }, [filter, cases]);

  const getCasesCallback = useCallback(async () => {
    try {
      if (!email) return;

      setLoading(true);

      const newCases = await getGradeCases({ assignedToGrader: email });
      if (newCases) {
        for (const newCase of newCases) {
          const epoch = '1970-01-01T00:00:00:000000';
          if (!newCase.date_created) {
            console.log(
              `case ${newCase.case_id} does not have a date_created set! Assigning '1970-01-01' as a default.`
            );
            newCase.date_created = epoch;
          }
        }
        const sortedCases = newCases.sort((a, b) => {
          const aDate = parseDateString(a.date_created.replace(' ', 'T'));
          const bDate = parseDateString(b.date_created.replace(' ', 'T'));
          if (!aDate || !bDate) return 0;
          return bDate.getTime() - aDate.getTime();
        });
        setCases(sortedCases);
        setFilteredCases(sortedCases);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [email]);

  useEffect(() => {
    if (!caseSelectedId) {
      void getCasesCallback();
    }
  }, [getCasesCallback, caseSelectedId]);

  return {
    loading,
    filter,
    cases: filteredCases,
    filterByGradedCases,
  };
};
