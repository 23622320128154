import { NavItem } from '@hooks/use-navigation-items';
import { SideMenuItem } from './side-menu-link';

type SubMenuProps = {
  items: NavItem[];
};

export function SubMenu({ items }: SubMenuProps) {
  return (
    <ul className="my-4 ml-4">
      {items.map((item, index) => (
        <SideMenuItem isSubMenuItem key={`${item.href}-${index}`} item={item} />
      ))}
    </ul>
  );
}
