/*
Cases - Cases is a Page component to view and interact with Cases
	One CustomTable component
	One InputSearchBar component
*/

import React from 'react';

import { toast_error } from '@libs/toast-wrappers';

import {
  ERROR_MESSAGE_REPORT_GENERATION,
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  ERROR_DOWNLOADING_REPORT,
  ERROR_FETCH_CASES,
  ERROR_FETCH_CAMERAS,
} from '@utils/messages';

import { getCases as getCasesRequest, postGenerateReport, putCaseFile } from '@api/cases';
import { getCameras as getCamerasRequest } from '@api/cameras';
import { parse_cookies } from '../react-utils/src/libformat';

import { Case, CASE_STATUS, CASE_STATUS_TEXT } from '../models/Case';

import CustomTable from '../lib-medical-portal/components/CustomTable';
import InputSearchBar from '../lib-medical-portal/components/FormInputs/InputSearchBar';
import FlyoutMenu from '../lib-medical-portal/components/FlyoutMenu';
import CreateCaseForm from './CreateCaseForm';
// import LoadOverlay from '../lib-medical-portal/components/LoadOverlay.jsx';
import { FetchStates } from './fetch-states';
import moment from 'moment';
import { logout } from '@libs/session-management';

//internationalization and translation support
import i18n from '@libs/i18n.js';
import { useUserProfile } from '@states/user-profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileChartColumn } from '@fortawesome/pro-light-svg-icons';
import { getTelehealthReport } from '@api/grade';
import { DownloadButton } from './download-button';

/*
props (component-level arguments):
	N/A

state (component level variables):
	caseArray: an array of all case objects
	caseArrayFiltered: a copy of caseArray modified as necessary (after sorting or searching)
	newCaseFormOpen: whether or not the "create case" form is currently open
	formID: the unique ID to use for the create case form
	caseProcessing: whether or not there is a case currently being processed that should be shown in the UI
	caseProcessingResult: the current progress status of the case being processed
	clinicId: the ID of the clinic that the logged-in user is a member of
	clinicName: the name of the clinic that the logged-in user is a member of
	newCaseId: the ID of the newly-created case, when a new case is created
	newCase: the Case object of the newly-created case, when a new case is created
	minImageCount: the minimum number of images required before a case can start processing
	formTitle: the title of the newCase form
	load_complete: whether or not all pending network requests have completed
	load_error: whether or not there was an error with a network request
	load_error_message: the error message to display in the case that network requests return an error
	nameSearch: the search string that is being searched for, in the case that a patient name search was performed
	cameraOptionArray: a list of dropdown options for cameras to select from
*/
class Cases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caseArray: [],
      caseArrayFiltered: [],
      newCaseFormOpen: false,
      formID: 'createNewCaseForm',
      caseProcessing: false,
      caseProcessingResult: CASE_STATUS.NOT_STARTED,
      clinicId: null,
      clinicName: null,
      newCaseId: null,
      newCase: null,
      minImageCount: 2,
      formTitle: i18n.t('case-create-title'),
      load_complete: false,
      load_error: false,
      load_error_message: '',
      nameSearch: '',

      cameraOptionArray: [],
    };

    this.openNewCaseForm = this.openNewCaseForm.bind(this);
    this.closeNewCaseForm = this.closeNewCaseForm.bind(this);
    this.closeProcessingDisplay = this.closeProcessingDisplay.bind(this);
    this.handleCreateNewCase = this.handleCreateNewCase.bind(this);
    this.handleStartCaseProcessing = this.handleStartCaseProcessing.bind(this);
    this.handleCaseProcessing = this.handleCaseProcessing.bind(this);
    this.handleProcessingComplete = this.handleProcessingComplete.bind(this);
    this.getCameras = this.getCameras.bind(this);
    this.eyestar_generate_report = this.eyestar_generate_report.bind(this);
    this.eyestar_dl_report = this.eyestar_dl_report.bind(this);
    this.renderCaseInProgress = this.renderCaseInProgress.bind(this);
    this.onClickSearchIcon = this.onClickSearchIcon.bind(this);
  }

  componentDidMount() {
    this.prepRealData();
    this.getCameras();

    let cookies = parse_cookies();
    if (cookies.hasOwnProperty('visionquest-user-clinic')) {
      this.setState({
        clinicId: cookies['visionquest-user-clinic'],
      });
    }
    if (cookies.hasOwnProperty('visionquest-clinic-name')) {
      this.setState({
        clinicName: cookies['visionquest-clinic-name'],
      });
    }
  }

  handleCreateNewCase(caseData) {
    this.setState({
      newCaseId: caseData.getCaseId(),
      newCase: caseData,
    });
  }

  handleStartCaseProcessing(caseData) {
    //		this.closeMenu()
    this.setState({
      newCaseFormOpen: false,
      caseProcessing: true,
      newCaseId: caseData.getCaseId(),
      newCase: caseData,
    });
    return caseData;
  }

  handleCaseProcessing(caseProcessingResult) {
    this.setState({
      caseProcessingResult: caseProcessingResult,
    });
  }

  handleProcessingComplete(caseProcessingResult) {
    this.setState({
      //			caseProcessing:false,
      caseProcessingResult: caseProcessingResult,
    });
  }

  getCameras() {
    // TODO: at this moment we are getting CORS error on this endpoint, so we are removing for now
    // getCamerasRequest().then((data) => {
    //   this.setState({
    //     cameraOptionArray: data ?? [],
    //   });
    // });
    // TODO: we update the request, remove the comments when it gets fully tested
    // aws_api_call.get_rqst(
    //   '/clinics/cameras',
    //   function (xhr) {
    //     let data = JSON.parse(xhr.response);
    //     if (data.hasOwnProperty('errorMessage')) {
    //       toast_error(ERROR_FETCH_CAMERAS);
    //     } else {
    //       let cameraOptionArray = [];
    //       if (typeof data.cameras === 'string') {
    //         let camObj = {};
    //         camObj[data.cameras] = data.cameras;
    //         cameraOptionArray.push(camObj);
    //       } else {
    //         for (let cameraIdx = 0; cameraIdx < data.cameras.length; cameraIdx++) {
    //           let camObj = {};
    //           camObj[data.cameras[cameraIdx]] = data.cameras[cameraIdx];
    //           cameraOptionArray.push(camObj);
    //         }
    //       }
    //       this.setState({
    //         cameraOptionArray: cameraOptionArray,
    //       });
    //     }
    //   }.bind(this),
    //   {},
    //   function (error) {
    //     if (error.status === 403) {
    //       logout();
    //     } else {
    //       toast_error(ERROR_FETCH_CAMERAS);
    //     }
    //   }
    // );
  }

  openNewCaseForm() {
    this.setState({
      newCaseFormOpen: true,
      newCaseId: null,
      newCase: null,
      minImageCount: 2,
      formTitle: i18n.t('case-create-title'),
    });
  }

  closeNewCaseForm() {
    //always update the case list when the new case form display is closed
    //this is so that any newly-created or newly-modified cases show up in the list correctly
    this.prepRealData();

    document.getElementById(`${this.state.formID}`).reset();
    this.setState({
      newCaseFormOpen: false,
      newCaseId: null,
      newCase: null,
    });
  }

  closeProcessingDisplay(reopen = false) {
    //always update the case list when the case processing display is closed
    //this is so that any newly-created or newly-modified cases show up in the list correctly
    this.prepRealData();

    this.setState({
      caseProcessing: false,
      caseProcessingResult: CASE_STATUS.NOT_STARTED,
    });
    if (reopen) {
      this.openNewCaseForm();
    }
  }

  eyestar_generate_report(dl_file_name = 'report.pdf', file_name = 'report.pdf', button_id = null) {
    let old_button_text = 'Download Report';
    let button = document.getElementById(button_id);
    if (button !== null) {
      old_button_text = button.innerText;
      button.innerText = i18n.t('cases-generating-report');
    }

    postGenerateReport(this.state.newCaseId, dl_file_name).then((data) => {
      let response = data;
      button.innerText = old_button_text;
      this.eyestar_dl_report(dl_file_name, file_name);
    });
  }

  eyestar_dl_report(dl_file_name = 'report.pdf', file_name = 'report.pdf') {
    getCaseFile(this.state.newCaseId, dl_file_name).then((data) => {
      const pdf_link = document.createElement('a');

      // TODO: we should validate if this response requires a blob conversion
      const pdf_blob = data;

      pdf_link.setAttribute('href', window.URL.createObjectURL(pdf_blob));
      pdf_link.setAttribute('download', file_name);
      pdf_link.style.display = 'none';
      document.body.appendChild(pdf_link);

      pdf_link.click();

      document.body.removeChild(pdf_link);
    });
  }

  put_case_file(case_id, file_name, file_type, file_data) {
    putCaseFile(case_id, file_name, file_data, file_type).then((data) => {
      console.log('put_case_file Success!');
      let response = data;
      console.log(response);
      //debug
      let case_type = 'zip';
      switch (file_type) {
        case 'application/zip':
          case_type = 'zip';
          break;

        case 'image/jpeg':
          case_type = 'jpeg';
          break;

        default:
          case_type = 'list';
          break;
      }
      this.eyestar_init(case_id, case_type, 1);
    });
  }

  prepRealData() {
    const user = 'all';
    const patient = 'all';
    const clinic = useUserProfile.getState().profile.clinic ?? 'all';

    if (!clinic) return;

    this.setState({
      load_complete: false,
    });

    getCasesRequest({
      user,
      clinic,
      patient,
      expand: ['grades'],
    })
      .then((response) => {
        this.moldData(response, false);
        this.setState({ load_complete: true });
      })
      .catch(() => {
        this.setState({
          load_complete: true,
          load_error: true,
          load_error_message: (
            <div>
              <h2>{ERROR_FETCH_CASES}</h2>
              <p>{ERROR_MESSAGE_TRY_AGAIN_EMAIL}</p>
            </div>
          ),
        });
      });
  }

  moldData(cases, isFiltered) {
    let statusColors = {};
    statusColors[CASE_STATUS_TEXT[CASE_STATUS.INSUFFICIENT_QUALITY]] = '#808080';
    //		statusColors[CASE_STATUS_TEXT[CASE_STATUS.NO_ACTION_NEEDED]]='#14C474';
    statusColors[CASE_STATUS_TEXT[CASE_STATUS.SEE_DOCTOR_ASAP]] = '#E34B2D';
    statusColors[CASE_STATUS_TEXT[CASE_STATUS.IN_PROGRESS]] = '#FBB040';
    statusColors[CASE_STATUS_TEXT[CASE_STATUS.RETURN_6_MO]] = '#14C474';
    statusColors[CASE_STATUS_TEXT[CASE_STATUS.RETURN_12_MO]] = '#14C474';

    let iconColorEdit = 'gray';
    let iconColorDownload = 'blue';

    let caseArray = [];
    try {
      // let responseObject = JSON.parse(response);
      for (var i = 0; i < cases.length; i++) {
        let responseCase = cases[i];

        let dateCreated = responseCase['date_created'];
        let colonCount = 0;
        let indexLastColon = -1;
        // date returned from server contains milliseconds which is incompatible with moment. Thus, remove milliseconds.
        for (let i = 0; i < dateCreated.length; i++) {
          if (dateCreated.charAt(i) === ':') {
            if (colonCount === 2) {
              indexLastColon = i;
              break;
            } else {
              colonCount++;
            }
          }
        }

        if (indexLastColon !== -1) {
          dateCreated = dateCreated.substring(0, indexLastColon);
        }

        let caseObj = new Case(
          new Date(moment(dateCreated).format('YYYY-MM-DDTHH:mm')).toISOString()
        );
        caseObj.setCaseId(responseCase['case_id']);
        caseObj.setResult(CASE_STATUS_TEXT[responseCase['result']]);
        caseObj.setPatientName(responseCase['patient_name']);
        caseObj.setCreatedBy(responseCase['user_id']);
        caseObj.setPatientId(responseCase['patient_id']);
        caseObj.setClinicId(responseCase['clinic_id']);

        switch (responseCase['result']) {
          case CASE_STATUS.SEE_DOCTOR_ASAP:
          case CASE_STATUS.RETURN_6_MO:
          case CASE_STATUS.RETURN_12_MO:
            caseObj.setAction(() => this.actionDownload(caseObj));
            caseObj.setIconColor(iconColorDownload);
            caseObj.setStatus('Complete');
            caseObj.setFaIconName('far fa-arrow-alt-circle-down');
            break;
          default:
            caseObj.setAction(() => this.actionEdit(caseObj));
            caseObj.setIconColor(iconColorEdit);
            caseObj.setStatus('Incomplete');
            caseObj.setFaIconName('far fa-edit');
            break;
        }

        if (responseCase['grades'] && responseCase['grades'].length > 0) {
          const file_name =
            'Telehealth_Report - ' +
            (responseCase['patient_name'] ?? responseCase['patient_id']) +
            '.pdf';

          caseObj.setReportDownload(
            <div className="inline-flex gap-2">
              {responseCase['grades'].map((grade) => (
                <div
                  key={`${grade['case_id']}-${grade['grade_id']}`}
                  className="inline-flex flex-col items-center"
                >
                  <DownloadButton
                    onDownload={() => getTelehealthReport(grade['grade_id'])}
                    fileName={file_name}
                  />
                  <span className="text-xs">{grade['user_email'].split('@')[0]}</span>
                </div>
              ))}
            </div>
          );
        }

        caseObj.setStringColorPair(statusColors);

        caseArray.push(caseObj);
      }
    } catch (e) {
      console.error(e);
    }

    //sort cases by date, newest first, by default
    caseArray.sort(function (a, b) {
      return b.getCreatedAt() - a.getCreatedAt();
    });

    if (isFiltered) {
      this.setState({
        caseArrayFiltered: caseArray,
      });
    } else {
      this.setState({
        caseArray: caseArray,
        caseArrayFiltered: caseArray,
      });
    }
  }

  actionEdit(caseObj) {
    console.log('edit - ', caseObj.getCaseId());

    //NOTE: we need case ID to be null for one render cycle in order to clear out the file upload form
    this.setState({
      newCaseFormOpen: false,
      newCaseId: null,
      newCase: null,
    });

    //after clearing out the file upload form, render the case edit dialog
    setTimeout(
      function () {
        this.setState({
          newCaseFormOpen: true,
          caseProcessing: false,
          caseProcessingResult: CASE_STATUS.NOT_STARTED,
          newCaseId: caseObj.getCaseId(),
          newCase: caseObj,
          //since an image might have already been uploaded, we only REQUIRE a single image for an update
          //although of course you can upload more than one image
          minImageCount: 1,
          formTitle: i18n.t('case-create-edit-title') + ' ' + caseObj.getPatientId(),
        });
      }.bind(this),
      100
    );
  }

  actionDownload(caseObj) {
    console.log('download - ', caseObj.getCaseId());
    let caseId = caseObj.getCaseId();
    let file_name =
      'EyeStar_Report_' + this.state.clinicName + '_' + caseObj.getPatientId() + '.pdf';

    this.setState(
      {
        newCaseId: caseId,
      },
      () => this.eyestar_dl_report('report.pdf', file_name)
    );
  }

  onClickSearchIcon(context) {
    return function (event) {
      event.preventDefault();

      let filterValue = document.getElementById('case-search-bar').value;
      //if no search is being performed
      if (filterValue === '') {
        //if the data we currently have loaded is the result of a search
        if (this.state.nameSearch !== '') {
          //then use the complete data instead of using any filtered data
          //NOTE: this does NOT require a new network call
          this.setState({
            nameSearch: '',
            caseArrayFiltered: this.state.caseArray,
          });
        }
        //if a search is being performed
      } else {
        //show the loading indicator while the search is being performed
        this.setState({
          load_complete: false,
          nameSearch: filterValue,
        });

        let cookies = parse_cookies();
        let clinic = useUserProfile.getState().profile.clinic;
        let user = 'all';
        let patient = 'all';

        if (!clinic) return;

        getCasesRequest({
          clinic,
          user,
          patient,
          patient_name: filterValue,
        }).then((data) => {
          //if this wasn't for the most recent search term
          //then ignore the response (we will get a new response from the more-recent search instead)
          if (filterValue !== this.state.nameSearch) {
            return;
          }

          let response = data;
          this.moldData(response, true);
          this.setState({
            load_complete: true,
          });
        });
      }
    }.bind(this);
  }

  updateTableContent(component, array) {
    component.setState({
      caseArrayFiltered: array,
    });
  }

  textForStatusCode(statusCode) {
    if (statusCode in CASE_STATUS_TEXT) {
      return CASE_STATUS_TEXT[statusCode];
    }
    return statusCode;
  }

  renderCaseInProgress(processingComplete, caseProcessingText) {
    let progressPercent = 0;
    let processingIcon = '';
    switch (this.state.caseProcessingResult) {
      default:
      case CASE_STATUS.NOT_SUBMITTED:
      case CASE_STATUS.PENDING:
      case CASE_STATUS.DATA_NOT_PROCESSED:
      case CASE_STATUS.NO_DATA_FOUND:
        //if no image applies, then don't display an image
        break;
      case CASE_STATUS.NOT_STARTED:
      case CASE_STATUS.IN_PROGRESS:
        // processingIcon = require('../images/assets/In Progress Icon.png').default;
        progressPercent = 50;
        break;
      case CASE_STATUS.INSUFFICIENT_QUALITY:
        // processingIcon = require('../images/assets/Invalid Result Icon.png').default;
        progressPercent = 100;
        break;
      case CASE_STATUS.SEE_DOCTOR_ASAP:
        // processingIcon = require('../images/assets/See Doctor Icon.png').default;
        progressPercent = 100;
        break;
      case CASE_STATUS.RETURN_6_MO:
      case CASE_STATUS.RETURN_12_MO:
        progressPercent = 100;
        break;
    }
    return (
      <div className="flyout-cont">
        <div className="CaseProcessingOverlay open">
          <div className={'case-status case-status-' + this.state.caseProcessingResult}>
            {processingComplete ? (
              <div>
                <img
                  className="processing-image complete"
                  src={processingIcon}
                  alt={i18n.t('cases-processing-complete')}
                />
                <h2 className="status-title">{i18n.t('cases-analysis-complete')}</h2>
                <h3>{caseProcessingText}</h3>
              </div>
            ) : (
              <div>
                <img
                  className="processing-image in-progress"
                  src={processingIcon}
                  alt={i18n.t('cases-processing-progress')}
                />
                <h2 className="status-title">{i18n.t('cases-processing')}</h2>
                <progress value={progressPercent} max="100"></progress>
                <p>{i18n.t('cases-be-patient')}</p>
                <p className="fineprint">{caseProcessingText}</p>
              </div>
            )}
            {processingComplete ? (
              <div className="grid-x grid-padding-x button-grid">
                <div className="small-12 medium-6 cell">
                  <button
                    id="report-dl-link"
                    className="button"
                    onClick={(e) =>
                      this.eyestar_generate_report(
                        'report.pdf',
                        'EyeStar_Report_' +
                          this.state.clinicName +
                          '_' +
                          this.state.newCase.getPatientId() +
                          '.pdf',
                        'report-dl-link'
                      )
                    }
                  >
                    {i18n.t('cases-dl-report')}
                  </button>
                </div>
                <div className="small-12 medium-6 cell">
                  <button className="button" onClick={(e) => this.closeProcessingDisplay(true)}>
                    {i18n.t('cases-submit-another')}
                  </button>
                </div>
                <div className="small-12 cell">
                  <button className="button" onClick={(e) => this.closeProcessingDisplay(false)}>
                    {i18n.t('cases-back-to-list')}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let fields = [
      { title: i18n.t('cases-table-patient-id'), attribute: 'patientId' },
      { title: i18n.t('cases-table-patient-name'), attribute: 'patientName' },
      { title: i18n.t('cases-table-created'), attribute: 'createdAt' },
      // { title: i18n.t('cases-table-result'), attribute: 'result' },
      // { title: i18n.t('cases-table-status'), attribute: 'status' },
      { title: i18n.t('cases-table-report'), attribute: 'report' },
      // { title: '', attribute: 'faIconName' },
    ];

    let columnWidths = [15, 15, 28, 5];

    let caseProcessingText = this.textForStatusCode(this.state.caseProcessingResult);
    let processingComplete =
      this.state.caseProcessingResult !== CASE_STATUS.NOT_STARTED &&
      this.state.caseProcessingResult !== CASE_STATUS.IN_PROGRESS;

    return (
      <div className="Cases">
        <div className="case-table-wrapper">
          <div className="title-search-button-wrapper">
            <span className="case-table-title">{i18n.t('cases-title')}</span>
            <div className="search-bar-button-wrapper">
              <InputSearchBar
                searchClickEvent={function () {}}
                submitOverride={this.onClickSearchIcon}
                searchContext={this}
                formId="case-search-form"
                fieldId="case-search-bar"
                formClass="case-search-bar"
                fieldHint={i18n.t('cases-search-title')}
              />
              {/* <FlyoutMenu
                buttonText={i18n.t('cases-new-btn')}
                closeButtonText={i18n.t('default-cancel')}
                menuOpen={this.state.newCaseFormOpen}
                openMenu={this.openNewCaseForm}
                closeMenu={this.closeNewCaseForm}>
                <CreateCaseForm
                  formId={this.state.formID}
                  caseId={this.state.newCaseId}
                  caseObj={this.state.newCase}
                  clinicId={this.state.clinicId}
                  newCaseCallback={this.handleCreateNewCase}
                  newCaseProcessingCallback={this.handleStartCaseProcessing}
                  progressUpdateCallback={this.handleCaseProcessing}
                  processingCompleteCallback={this.handleProcessingComplete}
                  minImageCount={this.state.minImageCount}
                  formTitle={this.state.formTitle}
                  cameraOptionArray={this.state.cameraOptionArray ?? []}
                />
              </FlyoutMenu> */}
            </div>
          </div>
          <FetchStates
            loading={!this.state.load_complete}
            error={this.state.load_error_message}
            reloadOnError
          >
            <div className="custom-table-wrapper">
              <CustomTable
                fields={fields}
                content={this.state.caseArrayFiltered}
                columnWidths={columnWidths}
                parentContext={this}
                sortable={true}
                sortableColumn={[true, true, true, true, true]}
              />
            </div>
          </FetchStates>
        </div>
        {this.state.caseProcessing
          ? this.renderCaseInProgress(processingComplete, caseProcessingText)
          : null}
        {/* <LoadOverlay
          show={this.state.load_error || !this.state.load_complete}
          error={this.state.load_error}
          errorMessage={this.state.load_error_message}
        /> */}
      </div>
    );
  }
}

export default Cases;
