import { useEffect, useState } from 'react';
import { CountryOption, getCountries } from '@api/countries';
import { errorlog } from '@libs/error-logs';

export function useCountriesOptions() {
  const [countries, setCountries] = useState<CountryOption[]>([]);

  useEffect(() => {
    if (countries.length > 0) return;

    getCountries()
      .then((data) => data && setCountries(data))
      .catch((error: Error) => {
        errorlog(error, 'src/hooks/use-contries-options.ts');
      });
  }, [countries, setCountries]);

  return { COUNTRIES: countries };
}
