import { useState, useMemo, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import { useUserProfile } from '@states/user-profile';
import { useGetUserProfile } from '@hooks/use-get-user-profile';
import { useCountriesOptions } from '@hooks/use-countries-options';

import { toast_warning } from '@libs/toast-wrappers';
import { devlog } from '@libs/dev-logs';

import { InputField } from './form/input-field';
import { SelectField } from './form/select-field';
import { NewLicenseForm } from './profile/new-license-form';
import { Table } from './table';
import { Hr } from './divider';
import { LoadingOverlay } from './loading-overlay';

type InputTypes = {
  full_name: string;
  clinic_name: string;
  email: string;
  contact_phone: string;
  street_address: string;
  city: string;
  province_or_state: string;
  postal_code: string;
  country: string;
};

export function ProfilePage() {
  const { register, handleSubmit, setValue } = useForm<InputTypes>();

  // refresh the user profile
  const { loading, refresh: refreshProfile } = useGetUserProfile();
  const { profile } = useUserProfile((state) => ({ profile: state.profile }));

  const [saving, setLoading] = useState(false);
  const [addingNewLicense, setAddingNewLicense] = useState(false);

  const { t } = useTranslation();
  const { COUNTRIES } = useCountriesOptions();

  const isGraderUser = useMemo(() => profile?.role === 'grader', [profile]);

  const disabledInputs = loading || saving;

  const onSubmitUpdateProfile: SubmitHandler<InputTypes> = (data) => {
    setLoading(true);
    setTimeout(() => {
      toast_warning('You will be able to update your profile soon!');
      devlog(data);
      setLoading(false);
    }, 500);
  };

  const onFinishSaveLicense = () => {
    setAddingNewLicense(false);
    setTimeout(() => {
      void refreshProfile();
    }, 500);
  };

  // country, state, license #, expiration, license upload, and approved
  const licensesTableHeaders = useMemo(
    () => [
      { key: 'license_number', label: 'License #' },
      { key: 'state_province', label: 'License State' },
      { key: 'expiration', label: 'Expiration Date' },
      { key: 'request_state', label: 'State', type: 'request-state' as const },
      {
        key: 'actions',
        type: 'actions' as const,
        edit: () => toast_warning('Edit licenses will be here soon!'),
        delete: () => toast_warning('Delete licenses will be here soon!'),
      },
    ],
    []
  );

  useEffect(() => {
    if (profile.full_name) {
      setValue('full_name', profile.full_name);
      setValue('clinic_name', profile.clinic_name ?? '');
      setValue('email', profile?.email ?? '');
      setValue('contact_phone', profile.contact_phone ?? '');
      setValue('street_address', profile.street_address ?? '');
      setValue('city', profile.city ?? '');
      setValue('province_or_state', profile.province_or_state ?? '');
      setValue('postal_code', profile.postal_code ?? '');
      setValue('country', profile.country ?? '');
    }
  }, [profile, register, setValue]);

  return (
    <div className="page-content-wrapper paper">
      <header className="page-header">
        <h1 className="page-title">{t('profile-title')}</h1>
        <p className="bold">
          {profile?.email}
          {profile.clinic_name ? ` - ${profile.clinic_name}` : ''}
        </p>
      </header>
      <section className="page-content">
        <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit(onSubmitUpdateProfile)}>
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-full-name')}
            {...register('full_name', { required: true })}
          />
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-phone')}
            {...register('contact_phone', { required: true })}
          />
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-address')}
            classNameFieldSet="col-span-2"
            {...register('street_address', { required: true })}
          />
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-city')}
            {...register('city', { required: true })}
          />
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-state')}
            {...register('province_or_state', { required: true })}
          />
          <InputField
            required
            disabled={disabledInputs}
            label={t('signup-postal')}
            {...register('postal_code', { required: true })}
          />
          <SelectField
            required
            disabled={disabledInputs}
            label={t('signup-country')}
            id="country-select"
            options={COUNTRIES}
            {...register('country', { required: true })}
          />
          <div className="text-note col-start-1">{t('signup-required-legend')}</div>
          <div className="col-start-1">
            <button type="submit" className="button" disabled={disabledInputs}>
              {saving ? `... ${t('updating')}` : t('update')}
            </button>
          </div>
        </form>
      </section>
      {isGraderUser && (
        <>
          <Hr />
          <section className="page-content">
            <header className="page-header">
              <h2 className="page-subtitle">{t('profile-licenses-title')}</h2>
              {!addingNewLicense && (
                <button
                  type="button"
                  className="inline-flex-full-center gap-2 text-primary"
                  onClick={() => setAddingNewLicense(true)}
                >
                  <span>{t('profile-add-license')}</span>
                  <FontAwesomeIcon icon={faCirclePlus} size="2xl" />
                </button>
              )}
            </header>
            {addingNewLicense && (
              <div className="mb-12">
                <NewLicenseForm onSuccessSave={onFinishSaveLicense} />
              </div>
            )}
            <Table headers={licensesTableHeaders} data={profile.licenses ?? []} />
          </section>
        </>
      )}
      <LoadingOverlay show={loading} />
    </div>
  );
}
