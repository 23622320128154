import { ChangeEventHandler, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

import { get_language_setting } from '@utils/translations';
import { errorlog } from '@libs/error-logs';

// components
import InputDropdown from '../lib-medical-portal/components/FormInputs/InputDropdown';

type LanguageSwitcherProps = {
  isSplashPage?: boolean;
};

export const LanguageSwitcher = ({ isSplashPage = false }: LanguageSwitcherProps) => {
  const [initialLanguage] = useState(get_language_setting());
  const { i18n } = useTranslation();

  const handleChangeLanguage: ChangeEventHandler<HTMLSelectElement> = (e) => {
    i18n.changeLanguage(e.target.value).catch((error) => {
      errorlog(error as Error, 'src/components/language-switcher.tsx', 'Error changing language');
    });
  };

  return (
    <div
      className={isSplashPage ? 'language-switcher language-splash' : 'language-switcher left-pad'}
    >
      <div className={isSplashPage ? 'inner-wrapper' : 'inner-wrapper border'}>
        <FontAwesomeIcon icon={faGlobe} color="#8cc3e1" />
        <InputDropdown
          dropdownClass="language-dropdown"
          dropdownId="language-dropdown"
          optionData={[{ en: 'EN' }, { es: 'ESP' }]}
          includeDefault={false}
          callback={handleChangeLanguage}
          defaultValue={initialLanguage}
        />
      </div>
    </div>
  );
};
