/**
 * Convert boolean to string 'Yes' or 'No'
 * @param str expect to be 'Yes' or 'No'
 * @returns
 */
export const decisionStrToBool = (str: string) => {
  return str.toLowerCase() === 'yes';
};

export const decisionBoolToStr = (bool: boolean) => {
  return bool ? 'yes' : 'no';
};
