import { useMemo, useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLongToLine, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { faFilter as faFilterOutlined } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

import { useGetGradingCases } from '@hooks/use-get-grading-cases';

import { FetchStates } from './fetch-states';
import { Table } from './table';
import { Hr } from './divider';
import { ROUTES } from '@router/routes';
import { parseDateString } from '@utils/dates';
import { LabelValue } from './label-value';

export function GradingPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { caseId: caseSelectedId } = useParams();

  const { cases, loading, filter, filterByGradedCases } = useGetGradingCases();

  const caseSelected = useMemo(
    () => cases.find((c) => c.case_id === caseSelectedId),
    [cases, caseSelectedId]
  );

  const formatDate = useCallback(
    (
      date: unknown // format date from '2024-03-14T14:57:21:139969'
    ) => parseDateString(date as string)?.toLocaleDateString() ?? 'Invalid Date',
    []
  );

  const formatGradeState = useCallback(
    (grades: unknown) => ((grades as string).length > 0 ? '✅' : '➖') as string,
    []
  );

  const formatId = useCallback((id: unknown) => {
    if (typeof id === 'string') {
      return id ? id.slice(0, 3) + '..' + id.slice(-5) : '';
    }
    return '';
  }, []);

  const tooltipId = useCallback(
    (case_row: unknown) => (case_row as (typeof cases)[number]).case_id,
    []
  );

  const formatLocation = useCallback((location: unknown) => {
    if (!location) return '-';

    const loc = location as { city: string; country: string; province_or_state: string };
    return `${loc.city}, ${loc.province_or_state}`;
  }, []);

  const gradesTableHeaders = useMemo(
    () => [
      {
        key: 'case_id',
        label: 'Case #',
        type: 'value' as const,
        format: formatId,
        tooltip: tooltipId,
      },
      { key: 'date_created', label: 'Created at', format: formatDate },
      { key: 'location', label: 'Location', format: formatLocation },
      {
        key: 'grades',
        label: 'Graded',
        format: formatGradeState,
        action: (
          <button onClick={filterByGradedCases}>
            <FontAwesomeIcon icon={filter.graded ? faFilter : faFilterOutlined} />
          </button>
        ),
      },
    ],
    [formatId, tooltipId, formatDate, formatGradeState, formatLocation, filter, filterByGradedCases]
  );

  const handleClickCase = useCallback(
    (row: (typeof cases)[number]) => {
      navigate(`${ROUTES.GRADING}/${row.case_id}`, { state: { case: row } });
    },
    [navigate]
  );

  return (
    <div className="page-content-wrapper paper">
      <header className="page-header">
        <h1 className="page-title">{t('grading-title')}</h1>
        {caseSelected && (
          <div className="inline-flex-full-center gap-12">
            <div className="inline-flex gap-8">
              <LabelValue label="User Email" value={caseSelected.user_email} />
              <LabelValue label="Created At" value={formatDate(caseSelected.date_created)} />
            </div>
            <button type="button" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeftLongToLine} />
            </button>
          </div>
        )}
      </header>
      <FetchStates loading={loading && !caseSelected}>
        <div className="page-content">
          {!caseSelected && (
            <Table
              headers={gradesTableHeaders}
              data={cases}
              onRowClick={handleClickCase}
              className=""
            />
          )}
        </div>
      </FetchStates>
      <Hr />
      <Outlet />
    </div>
  );
}
