import { useCallback, useMemo, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { SignGradeForm } from '@hooks/use-grade-form-logic';

import { Overlay } from '@components/overlay';
import { Carousel } from '@components/carousel';
import { RadioGroupOption } from '@components/form/radio-group-opt';

type GradeCaseImagesProps = {
  images: { path: string; file: File }[];
  watch: UseFormWatch<SignGradeForm>;
  setFormValue: UseFormSetValue<SignGradeForm>;
  disabled?: boolean;
};

export function GradeCaseImages(props: GradeCaseImagesProps) {
  const { images: imagesFiles, watch, setFormValue, disabled } = props;
  const [expanded, setExpanded] = useState<number | undefined>();
  const { t } = useTranslation();

  const images = useMemo(
    () => imagesFiles.map((i) => ({ path: i.path, url: URL.createObjectURL(i.file) })),
    [imagesFiles]
  );
  const imagesState = watch('imagesReadable_string'); // JSON object with an array of { img: string, isReadable: 'yes' or 'no' }
  const listImgState = JSON.parse(imagesState ?? '[]') as {
    img: string;
    isReadable: string;
  }[];
  console.log({ listImgState });

  const handleClose = useCallback(() => {
    setExpanded(undefined);
  }, []);

  const handleExpand = useCallback((index: number) => {
    setExpanded(index);
  }, []);

  const imageChildren = (img: string) => {
    const currentState = listImgState?.find((state) => state.img === img) ?? {
      isReadable: '',
    };

    const handleSwitchChange = (checked: boolean) => {
      const newState = listImgState.map((state) =>
        state.img === img ? { ...state, isReadable: checked ? 'yes' : 'no' } : state
      );
      setFormValue('imagesReadable_string', JSON.stringify(newState));
    };

    return (
      <div className="absolute top-12 right-0 rounded-lg bg-white bg-opacity-90 p-4 inline-flex flex-col">
        <p>Is this image readable?</p>
        <RadioGroupOption
          label={t('yes')}
          id={`${img}-readable-yes`}
          value="yes"
          required
          disabled={disabled}
          checked={currentState.isReadable === 'yes'}
          onChange={() => handleSwitchChange(true)}
        />
        <RadioGroupOption
          label={t('no')}
          id={`${img}-readable-no`}
          value="no"
          required
          disabled={disabled}
          checked={currentState.isReadable === 'no'}
          onChange={() => handleSwitchChange(false)}
        />
      </div>
    );
  };

  if (!images.length) {
    return (
      <p className="pb-4 italic text-gray-500 text-sm">-- No images available for this case --</p>
    );
  }

  return (
    <div className="mb-6 flex items-center gap-4 overflow-x-auto">
      {images.map((i, index) => {
        const image = listImgState.find((state) => state.img === i.path);
        const isImageUndefined = image?.isReadable === '';
        const isImageReadable = image?.isReadable === 'yes';

        const color = isImageReadable
          ? 'text-green-500'
          : isImageUndefined
            ? 'text-gray-500'
            : 'text-red-500';

        return (
          <div key={`grade-image-${index}`} className="h-24">
            <img
              className="h-20 pointer"
              src={i.url}
              alt={`Case img ${index + 1}`}
              onClick={() => handleExpand(index)}
            />
            <div className="h-4">
              {image ? (
                <p className={`text-xs text-center font-medium ${color}`}>
                  {isImageUndefined ? (
                    t('unanswered')
                  ) : isImageReadable ? (
                    <>
                      <span className="mr-1">{t('readable')}</span>
                      <FontAwesomeIcon icon={faBadgeCheck} />
                    </>
                  ) : (
                    <>
                      <span className="mr-1">{t('unreadable')}</span>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </>
                  )}
                </p>
              ) : null}
            </div>
          </div>
        );
      })}
      <Overlay open={expanded !== undefined} onClose={handleClose}>
        <div className="h-[80vh] w-full m-auto mt-[5%]">
          <Carousel images={images} initialSlide={expanded} imagesChildren={imageChildren} />
        </div>
      </Overlay>
    </div>
  );
}
