import { errorlog } from '@libs/error-logs';
import { toast_error } from '@libs/toast-wrappers';
import { GET } from './base/index';
import { ENDPOINTS } from './endpoints';
import { ERROR_FETCH_BILLING } from '@utils/messages';

export async function getCurrentBilling(clinic_id: string) {
  try {
    const response = await GET<unknown>(ENDPOINTS.CURRENT_BILLING, {
      query: { clinic_id },
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/billing/getCurrentBilling', 'Error fetching billing');
    toast_error(ERROR_FETCH_BILLING);
    return Promise.reject(error);
  }
}
