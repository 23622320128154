import { toast_error } from '@libs/toast-wrappers';
import { errorlog } from '@libs/error-logs';

import { DELETE, GET, GET_FILE, POST, PUT } from './base/index';
import { ENDPOINTS } from './endpoints';
import {
  ERROR_CREATING_CASE,
  ERROR_DOWNLOADING_REPORT,
  ERROR_FETCH_CASES,
  ERROR_GET_GRADE_CASES,
  ERROR_MESSAGE_REPORT_GENERATION,
} from '@utils/messages';
import { Grade } from './grade';

export type Case = {
  CSME: number;
  user_email: string;
  processing_time_seconds: number;
  clinic_id: string;
  result: number;
  date_created: string;
  date_submitted: string;
  DR: number;
  camera_manufacturer_model: string;
  date_resulted: string;
  case_id: string;
  patient_id: string;
  site_id: string;
  hasConflictingGrades_bool: boolean;
  grades?: Grade[];
  location?: {
    city: string;
    country: string;
    province_or_state: string;
  };
};

type GetCasesParams = {
  clinic?: string;
  patient?: string;
  patient_name?: string;
  user?: string;
  limit?: number;
  expand?: ('grades' | 'location')[];
};

export async function getCases(params?: GetCasesParams) {
  try {
    const query = {
      clinic: params?.clinic ?? 'all',
      patient: params?.patient ?? 'all',
      user: params?.user ?? 'all',
      limit: encodeURIComponent(params?.limit ?? 100),
      ...(params?.expand ? { expand: params.expand.join(',') } : {}),
      ...(params?.patient_name ? { patient_name: params.patient_name } : {}),
    };
    const response = await GET<Case[]>(ENDPOINTS.CASES, {
      query,
    });
    if ('cases' in response) {
      return response.cases;
    }
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getCases', 'Error fetching cases');
    toast_error(ERROR_FETCH_CASES);
    return Promise.reject(error);
  }
}

type GetGradeParams = Omit<GetCasesParams, 'expand'> & {
  assignedToGrader: string;
};

/**
 * Fetches the cases for the user
 * @param params the parameters for the request
 * @returns the cases for the user
 */
export async function getGradeCases(params: GetGradeParams) {
  try {
    const query = {
      clinic: params?.clinic ?? 'all',
      patient: params?.patient ?? 'all',
      user: params?.user ?? 'all',
      assignedToGrader: params?.assignedToGrader,
      expand: 'grades,location',
    };
    const response = await GET<Case[]>(ENDPOINTS.CASES, {
      query,
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getGradeCases', 'Error fetching cases for grading');
    toast_error(ERROR_GET_GRADE_CASES);
    return Promise.reject(error);
  }
}

type CaseResponseList = { result: string[] };
type CaseResponseScore = { result: string };
type CaseResponse = CaseResponseList | CaseResponseScore;

export async function getCase(caseId: string, type = 'list') {
  try {
    const response = await GET<CaseResponse>(ENDPOINTS.CASE, {
      query: { type },
      parameters: { caseId },
    });
    return response.result;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getCase', 'Error fetching case');
    toast_error('Error fetching case');
    return Promise.reject(error);
  }
}

export async function getCaseImage(imgPath: string) {
  try {
    const response = await GET_FILE(imgPath);
    return { path: imgPath, file: response };
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getCaseImage', 'Error fetching case');
    toast_error('Error fetching case image');
    return Promise.reject(error);
  }
}

export async function getCaseFile(caseId: string, filePath: string) {
  try {
    const fullEndpoint = ENDPOINTS.CASE_FILE.replace('{caseId}', caseId).replace(
      '{filePath}',
      filePath
    );
    const response = await GET_FILE(fullEndpoint);
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/getCaseFile', 'Error fetching case');
    toast_error(ERROR_DOWNLOADING_REPORT);
    return Promise.reject(error);
  }
}

export async function putCaseFile(caseId: string, filePath: string, file: File, fileType?: string) {
  try {
    const response = await PUT<File>(ENDPOINTS.CASE_FILE, file, {
      parameters: { caseId, filePath },
      headers: { 'Content-Type': fileType ?? 'application/octet-stream' },
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/putCaseFile', 'Error uploading case file');
    toast_error('Error uploading case file');
    return Promise.reject(error);
  }
}

export async function postGenerateReport(caseId: string, reportFileName: string) {
  try {
    const response = await POST(
      ENDPOINTS.GENERATE_REPORT,
      {}, // empty body
      {
        parameters: { caseId, dl_file_name: reportFileName },
      }
    );
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/postGenerateReport', 'Error generating report');
    toast_error(ERROR_MESSAGE_REPORT_GENERATION);
    return Promise.reject(error);
  }
}

type CaseForm = {
  patientID: string;
  patientName: string;
  site_id: string;
  camera: string;
  gender: string;
  ethnicity: string;
  hasDiabetes: string;
  diabetesType: string;
  yearDiagnosed: string;
  eyeHistory: string;
  notes: string;
};

export async function postCase(newCase: CaseForm) {
  try {
    const response = await POST<{ case: Case }>(ENDPOINTS.CASES, newCase);
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/postCase', 'Error creating case');
    toast_error(ERROR_CREATING_CASE);
    return Promise.reject(error);
  }
}

export async function postCaseInit(caseId: string, caseType: string, quality: string) {
  try {
    const response = await POST(
      ENDPOINTS.CASE,
      {}, // it use an empty body
      {
        parameters: { caseId },
        query: { type: caseType, quality },
      }
    );
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/postCaseInit', 'Error initializing case');
    toast_error('Error initializing case');
    return Promise.reject(error);
  }
}

export async function deleteFile(caseId: string, filePath: string) {
  try {
    const response = await DELETE(ENDPOINTS.CASE_FILE, {
      parameters: { caseId, filePath },
    });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/cases/deleteFile', 'Error deleting file');
    toast_error('Error deleting file');
    return Promise.reject(error);
  }
}
