/* eslint-disable */

/**
 * this function will be used only in cases when we need to keep a log for development
 * for example when we have partly implemented a feature and we want to keep a log of the data
 */
export const devlog = (...args: any[]) => {
  if (import.meta.env.DEV) {
    console.log('devlog', ...args);
  }
};
