/*
Dashboard - A Dashboard is a viewing area for analytical data and at-a-glance stats.
	A Dashboard consists of A StatBlock
*/

import React from 'react';

import { toast_error } from '@libs/toast-wrappers';

import { parse_cookies } from '../react-utils/src/libformat';
import { store_get_data } from '../react-utils/src/libgetdata.js';

import {
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  ERROR_MESSAGE_LOAD_FAILURE,
  ERROR_DASH_FETCH_USER_COUNT,
  ERROR_DASH_FETCH_CASE_COUNT,
  ERROR_DASH_FETCH_IMAGE_COUNT,
  ERROR_DASH_FETCH_PATIENT_COUNT,
  ERROR_DASH_STAT_HISTORY,
} from '@utils/messages';

import AnalyticsGraph from '../lib-medical-portal/components/AnalyticsGraph/AnalyticsGraph.jsx';
import StatBlock from '../lib-medical-portal/components/StatBlock/StatBlock.jsx';
// import LoadOverlay from '../lib-medical-portal/components/LoadOverlay.jsx';
import { FetchStates } from './fetch-states';

import { logout } from '@libs/session-management';

//internationalization and translation support
import i18n from '@libs/i18n.js';
import {
  getCasesCount,
  getCasesImagesCount,
  getPatientsCount,
  getUsersCount,
  getDashboardStats,
} from '@api/dashboard';

/*
props (component-level arguments):
	A Dashboard has no props

state (component-level globals):
	total_users: the number of users with respect to the statblock settings
	total_cases_created: the number of cases created with respect to the statblock settings
	total_photos_uploaded: the number of photos uploaded with respect to the statblock settings
	total_patients: the number of patients in the clinic with respect to the statblock settings
	graph_patients: the number of patients in the clinic with respect to the graph display settings
	referral_rate: the current-time referral rate (to show on the analytics graph tab heading)
	inadequate_rate: the current-time rate of inadequate photo quality (to show on the analytics graph tab heading)
	avg_processing_time: the current-time average processing time (to show on the analytics graph tab heading)
	graph_interval_value: the number of graph_interval_units being displayed on the graphs
	graph_interval_unit: the name of the interval unit being used for graph data
	graph_data: the list of data points to display on graph views
	loading_item_cnt: the number of items for which a network request is in progress
*/
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total_users: null,
      total_cases_created: null,
      total_photos_uploaded: null,
      total_patients: null,
      graph_patients: null,
      referral_rate: null,
      inadequate_rate: null,
      avg_processing_time: null,
      graph_interval_value: 7,
      graph_interval_unit: 'days',
      graph_data: [],
      loading_item_cnt: 0,
      load_error: false,
      load_error_message: '',
    };

    this.output_error = this.output_error.bind(this);
    this.wrap_output_error = this.wrap_output_error.bind(this);
    this.fetch_summary_stats = this.fetch_summary_stats.bind(this);
    this.fetch_graph_stats = this.fetch_graph_stats.bind(this);
    this.fetch_dash_stats = this.fetch_dash_stats.bind(this);
    this.render_analytics_graph = this.render_analytics_graph.bind(this);
    this.render_stat_block = this.render_stat_block.bind(this);
    this.graph_timeframe_callback = this.graph_timeframe_callback.bind(this);
    this.convert_counts_to_ratios = this.convert_counts_to_ratios.bind(this);
    this.convert_datestamps = this.convert_datestamps.bind(this);
    this.update_graph_data = this.update_graph_data.bind(this);
    this.date_cmp = this.date_cmp.bind(this);
  }

  componentDidMount() {
    this.fetch_dash_stats();
  }

  output_error(xhr, error_msg) {
    console.log('Error ' + xhr.status + ': ' + error_msg + ' ' + xhr.responseText);
    toast_error(error_msg + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    if (xhr.status === 403) {
      logout();
    }
  }

  wrap_output_error(error_msg) {
    let cmpnt = this;
    return function (xhr) {
      cmpnt.output_error(xhr, error_msg);

      //if an error occurred then this item is no longer in the "loading" state
      //and therefore the total number of items left to load should be reduced by one
      cmpnt.setState({
        loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        load_error: true,
        //NOTE: we are intentionally NOT setting any of the specific load error messages here but instead using a single generic error message
        //because the direct cause could be a variety of different things
        load_error_message: (
          <div>
            <h2>{ERROR_MESSAGE_LOAD_FAILURE}</h2>
            <p>{ERROR_MESSAGE_TRY_AGAIN_EMAIL}</p>
          </div>
        ),
      });
    };
  }

  //this function takes in a list of total values and converts them to ratios
  //in proportion to a reference field
  //arguments:
  //	graph_data: the list of data points to manipulate
  //	count_field: the field which holds the total values to be converted
  //	reference_field: the field which the values in count_field can be expressed as a percentage of
  //	ratio_field: the name of the new field to create which will store the ratios
  //	ratio_factor: a value to multiply all ratios by (use 100 to get ratios as percentages)
  //return:
  //	returns an updated version of graph_data with the ratio_field populated
  //side-effects:
  //	none
  convert_counts_to_ratios(
    graph_data,
    count_field,
    reference_field,
    ratio_field,
    ratio_factor = 1
  ) {
    for (var i = 0; i < graph_data.length; i++) {
      const data_point = graph_data[i];
      if (data_point[reference_field] !== 0) {
        graph_data[i][ratio_field] = data_point[count_field] / data_point[reference_field];
        graph_data[i][ratio_field] *= ratio_factor;
      } else {
        //since we can't divide by zero we are just going to set a zero value for this
        //this indicates a fundamental problem with the input data
        graph_data[i][ratio_field] = 0;
      }
    }
    return graph_data;
  }

  //this function converts datestamps into a more useable format
  //arguments:
  //	graph_data: the list of data points to manipulate, each of which should include a 'date' field
  //return:
  //	returns an updated version of graph_data with dates converted to remove milliseconds
  //side-effects:
  //	none
  convert_datestamps(graph_data) {
    for (var i = 0; i < graph_data.length; i++) {
      let date_str = graph_data[i]['date'];
      let date_parts = date_str.split(':');
      if (date_parts.length > 3) {
        date_parts = date_parts.slice(0, 3);
        date_str = date_parts.join(':');
      }
      graph_data[i]['date'] = date_str;
    }
    return graph_data;
  }

  fetch_summary_stats(get_data) {
    let get_params = store_get_data(get_data);

    //add to the pending network requests
    this.setState(
      {
        loading_item_cnt: this.state.loading_item_cnt + 4,
      },
      function () {
        //GET /users/count
        getUsersCount(get_data).then((count) => {
          if (!count) return;
          this.setState({
            total_users: count,
            //subtract from the pending network requests
            loading_item_cnt: this.state.loading_item_cnt - 1,
          });
        });
        // TODO: remove the following code after the above code is tested
        // aws_api_call.get_rqst(
        //   '/users/count' + get_params,
        //   (function (cmpnt) {
        //     return function (xhr) {
        //       let count = JSON.parse(xhr.responseText)['count'];
        //       count = count === undefined ? null : count;
        //       cmpnt.setState({
        //         total_users: count,
        //         //subtract from the pending network requests
        //         loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        //       });
        //     };
        //   })(this),
        //   { 'Content-Type': 'application/json' },
        //   this.wrap_output_error(ERROR_DASH_FETCH_USER_COUNT)
        // );

        //GET /cases/count
        getCasesCount(get_data).then((count) => {
          if (!count) return;
          this.setState({
            total_cases_created: count,
            //subtract from the pending network requests
            loading_item_cnt: this.state.loading_item_cnt - 1,
          });
        });
        // TODO: remove the following code after the above code is tested
        // aws_api_call.get_rqst(
        //   '/cases/count' + get_params,
        //   (function (cmpnt) {
        //     return function (xhr) {
        //       let count = JSON.parse(xhr.responseText)['count'];
        //       count = count === undefined ? null : count;
        //       cmpnt.setState({
        //         total_cases_created: count,
        //         //subtract from the pending network requests
        //         loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        //       });
        //     };
        //   })(this),
        //   { 'Content-Type': 'application/json' },
        //   this.wrap_output_error(ERROR_DASH_FETCH_CASE_COUNT)
        // );

        //GET /cases/images/count
        getCasesImagesCount(get_data).then((count) => {
          if (!count) return;
          this.setState({
            total_photos_uploaded: count,
            //subtract from the pending network requests
            loading_item_cnt: this.state.loading_item_cnt - 1,
          });
        });
        // TODO: remove the following code after the above code is tested
        // aws_api_call.get_rqst(
        //   '/cases/images/count' + get_params,
        //   (function (cmpnt) {
        //     return function (xhr) {
        //       let count = JSON.parse(xhr.responseText)['count'];
        //       count = count === undefined ? null : count;
        //       cmpnt.setState({
        //         total_photos_uploaded: count,
        //         //subtract from the pending network requests
        //         loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        //       });
        //     };
        //   })(this),
        //   { 'Content-Type': 'application/json' },
        //   this.wrap_output_error(ERROR_DASH_FETCH_IMAGE_COUNT)
        // );

        //GET /cases/patients/count
        getPatientsCount(get_data).then((count) => {
          if (!count) return;
          this.setState({
            total_patients: count,
            //subtract from the pending network requests
            loading_item_cnt: this.state.loading_item_cnt - 1,
          });
        });
        // TODO: remove the following code after the above code is tested
        // aws_api_call.get_rqst(
        //   '/cases/patients/count' + get_params,
        //   (function (cmpnt) {
        //     return function (xhr) {
        //       let count = JSON.parse(xhr.responseText)['count'];
        //       count = count === undefined ? null : count;
        //       cmpnt.setState({
        //         total_patients: count,
        //         //subtract from the pending network requests
        //         loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        //       });
        //     };
        //   })(this),
        //   { 'Content-Type': 'application/json' },
        //   this.wrap_output_error(ERROR_DASH_FETCH_PATIENT_COUNT)
        // );
      }.bind(this)
    );
  }

  date_cmp(data_a, data_b) {
    const date_a = new Date(data_a['date']);
    const date_b = new Date(data_b['date']);
    if (date_a < date_b) {
      return -1;
    } else if (date_a > date_b) {
      return 1;
    }
    return 0;
  }

  //update the component state to include newly-fetched graph data
  update_graph_data(overall_stats, graph_data) {
    if (!(Array.isArray(graph_data) && graph_data.length > 0)) {
      //if the graph data was not in an expected format then bail without updating state
      return;
    }

    graph_data = this.convert_datestamps(graph_data);

    //sort from oldest to newest so that the graph makes sense
    graph_data.sort(this.date_cmp);

    //convert totals into percentages as needed
    graph_data = this.convert_counts_to_ratios(
      graph_data,
      'refer_count',
      'patient_count',
      'refer_perc',
      100
    );
    graph_data = this.convert_counts_to_ratios(
      graph_data,
      'inad_count',
      'patient_count',
      'inad_perc',
      100
    );

    //the total number of patients is the sum of all the patients added during the specified time slice
    let graph_patients = graph_data.reduce(function (acc, value) {
      return acc + value.patient_count;
    }, 0);

    //NOTE: the -0 is a javascript-style typecast to ensure these are numeric values
    let referral_rate = overall_stats['percent_referall'] - 0; //[sic]
    let inadequate_rate = overall_stats['percent_inadequate'] - 0;
    let avg_processing_time = overall_stats['overall_average'] - 0;

    //NOTE: although they are calling this a "percent"
    //in the API-returned data it appears that the values we are provided are a ratio rather than a percentage
    //so we convert it to a percentage here
    referral_rate *= 100;
    inadequate_rate *= 100;

    //update the component state
    this.setState({
      graph_data: graph_data,
      graph_patients: graph_patients,
      referral_rate: referral_rate,
      inadequate_rate: inadequate_rate,
      avg_processing_time: avg_processing_time,
    });
  }

  fetch_graph_stats(get_data) {
    if (!get_data.hasOwnProperty('interval')) {
      get_data['interval'] = 'days';
      get_data['value'] = '7';
    }
    let get_params = store_get_data(get_data);

    //add to the pending network requests
    this.setState(
      {
        loading_item_cnt: this.state.loading_item_cnt + 1,
      },
      function () {
        //GET /cases/stats
        getDashboardStats(get_data).then((data) => {
          if (!data) return;
          this.update_graph_data(data['overall_stats'], data['dates']);
          //subtract from the pending network requests
          this.setState({
            loading_item_cnt: this.state.loading_item_cnt - 1,
          });
        });
        // TODO: remove the following code after the above code is tested
        // aws_api_call.get_rqst(
        //   '/cases/stats' + get_params,
        //   (function (cmpnt) {
        //     return function (xhr) {
        //       let response_obj = JSON.parse(xhr.responseText);
        //       if (response_obj.hasOwnProperty('errorMessage')) {
        //         toast_error(
        //           ERROR_DASH_STAT_HISTORY +
        //             ' ' +
        //             ERROR_MESSAGE_TRY_AGAIN_EMAIL +
        //             ' (' +
        //             response_obj['errorMessage'] +
        //             ')'
        //         );
        //       } else {
        //         cmpnt.update_graph_data(response_obj['overall_stats'], response_obj['dates']);
        //       }
        //       //subtract from the pending network requests
        //       cmpnt.setState({
        //         loading_item_cnt: cmpnt.state.loading_item_cnt - 1,
        //       });
        //     };
        //   })(this),
        //   { 'Content-Type': 'application/json' },
        //   this.wrap_output_error(ERROR_DASH_STAT_HISTORY)
        // );
      }.bind(this)
    );
  }

  fetch_dash_stats() {
    let clinic_id = 'all';
    let cookies = parse_cookies();
    if (cookies.hasOwnProperty('visionquest-user-clinic')) {
      clinic_id = encodeURIComponent(cookies['visionquest-user-clinic']);
    }
    let get_data = {
      clinic_id: clinic_id,
    };

    setTimeout(
      function () {
        this.fetch_summary_stats(get_data);
      }.bind(this),
      0
    );

    //NOTE: setTimeout is required here because otherwise the value of loading_item_cnt in state
    //will not get updated before this function is called
    //and that will mean that this function, instead of adding to the value previously set,
    //will set the new value as 1+ the value prior to calling fetch_dash_stats
    //so setTimeout is used to force this function to only be executed AFTER the setState from fetch_summary_stats completes
    setTimeout(
      function () {
        this.fetch_graph_stats(get_data);
      }.bind(this),
      1
    );
  }

  graph_timeframe_callback(event) {
    event.preventDefault();

    let clinic_id = 'all';
    let cookies = parse_cookies();
    if (cookies.hasOwnProperty('visionquest-user-clinic')) {
      clinic_id = encodeURIComponent(cookies['visionquest-user-clinic']);
    }
    let get_data = {
      clinic_id: clinic_id,
    };

    let timeframe = event.currentTarget.value.split(':');
    //if the specified timeframe didn't have a value component then assume the user meant "all time"
    if (timeframe.length < 2) {
      get_data['interval'] = 'all';
      get_data['value'] = 'all';

      //return early because the API doesn't actually implement "all time" for this endpoint
      return false;
    } else {
      get_data['interval'] = timeframe[0];
      get_data['value'] = timeframe[1];
    }

    //fetch updated graph data for the newly-selected timeframe
    this.fetch_graph_stats(get_data);

    //update the component state to include the new time units
    this.setState({
      graph_interval_unit: get_data['interval'],
      //NOTE: the -0 here converts the value to an integer, as AnalyticsGraph expects
      graph_interval_value: get_data['value'] - 0,
    });

    return false;
  }

  render_analytics_graph() {
    //NOTE: we keep analytics graph data separate from the stat block summary data as different intervals might be selected for each at the same time
    return (
      <AnalyticsGraph
        graph_title={i18n.t('dash-analytics-title')}
        patients_title={i18n.t('dash-patients-title')}
        refer_title={i18n.t('dash-refer-title')}
        inad_title={i18n.t('dash-inad-title')}
        time_title={i18n.t('dash-time-title')}
        total_patients={
          this.state.graph_patients === null ? '-' : this.state.graph_patients.toString()
        }
        referral_rate={
          this.state.referral_rate === null ? '-' : this.state.referral_rate.toFixed(2) + '%'
        }
        inadequate_rate={
          this.state.inadequate_rate === null ? '-' : this.state.inadequate_rate.toFixed(2) + '%'
        }
        avg_processing_time={
          this.state.avg_processing_time === null
            ? '-'
            : this.state.avg_processing_time.toFixed(2) + ' sec'
        }
        patient_count_history={this.state.graph_data}
        referral_history={this.state.graph_data}
        inadequate_history={this.state.graph_data}
        processing_history={this.state.graph_data}
        timeframe_callback={this.graph_timeframe_callback}
        interval_value={this.state.graph_interval_value}
        interval_unit={this.state.graph_interval_unit}
        date_range_dropdown_data={[
          { 'hours:24': i18n.t('dash-time-hours') },
          { 'days:7': i18n.t('dash-time-week') },
          { 'days:30': i18n.t('dash-time-month') },
        ]}
        default_select_text={i18n.t('default-select')}
      />
    );
  }

  render_stat_block() {
    return (
      <StatBlock
        stat_lines={[
          {
            label: i18n.t('dash-total-users'),
            value: this.state.total_users === null ? '-' : this.state.total_users,
          },
          {
            label: i18n.t('dash-total-cases'),
            value: this.state.total_cases_created === null ? '-' : this.state.total_cases_created,
          },
          {
            label: i18n.t('dash-total-patients'),
            value: this.state.total_patients === null ? '-' : this.state.total_patients,
          },
          {
            label: i18n.t('dash-total-photos'),
            value:
              this.state.total_photos_uploaded === null ? '-' : this.state.total_photos_uploaded,
          },
        ]}
        title={i18n.t('dash-stats-title')}
        timeframe_select={function (e) {
          let selected_value = e.currentTarget.value;
          let value_parts = selected_value.split(':');

          let clinic_id = 'all';
          let cookies = parse_cookies();
          if (cookies.hasOwnProperty('visionquest-user-clinic')) {
            clinic_id = encodeURIComponent(cookies['visionquest-user-clinic']);
          }
          let get_data = {
            clinic_id: clinic_id,
            interval: value_parts[0],
            value: value_parts[1],
          };
          this.fetch_summary_stats(get_data);
        }.bind(this)}
        time_title_all={i18n.t('dash-time-all')}
        time_title_hours={i18n.t('dash-time-hours')}
        time_title_week={i18n.t('dash-time-week')}
        time_title_month={i18n.t('dash-time-month')}
      />
    );
  }

  render() {
    return (
      <div className="Dashboard">
        <FetchStates
          loading={this.state.loading_item_cnt > 0}
          error={this.state.load_error_message}
          reloadOnError
        >
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 xlarge-9 xxlarge-8">{this.render_analytics_graph()}</div>
            <div className="cell small-12 xlarge-3 xxlarge-4">{this.render_stat_block()}</div>
          </div>
        </FetchStates>
        {/* <LoadOverlay
          show={this.state.load_error || this.state.loading_item_cnt > 0}
          error={this.state.load_error}
          errorMessage={this.state.load_error_message}
        /> */}
      </div>
    );
  }
}

export default Dashboard;
