/*
FileSelect - This will show the controls for uploading files to the server.

A File Upload Component is a Base Level Component and contains no sub components.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { putCaseFile } from '@api/cases';

const file_mimes = ['image/jpeg', 'application/zip', 'application/dicom'];

/*
props (component-level arguments):
	idUniqueIdentifier: a way to differentiate different File Upload Components that are on the same page. This is a required field.

state:
	dropAreaId: The ID of the drop area. Used in multiple functions as well as the Markup.
	fileInputId: the ID of the File Input tag. Used in multiple functions as well as the Markup.
*/
class FileSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropAreaId: '',
      fileInputId: '',
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      dropAreaId: `dropArea_${this.props.idUniqueIdentifier}`,
      fileInputId: `fileInput_${this.props.idUniqueIdentifier}`,
    });
  }

  componentDidMount() {
    const dropArea = document.getElementById(this.state.dropAreaId);

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.highlight, false);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, this.unhighlight, false);
    });

    dropArea.addEventListener('drop', this.handleDrop, false);
  }

  handleFiles(files) {
    const fileArray = [...files];
    for (let i = 0; i < fileArray.length; i++) {
      this.uploadFile(fileArray[i]);
    }
  }

  put_case_file(case_id, file_name, file_type, file) {
    //PUT /cases/{case_id}/{file_name}
    putCaseFile(case_id, file_name, file, file_type)
      .then((response) => {
        console.log('put_case_file Uploaded ' + file_name + ' Successfully');
        if (this.props.uploadCompletedCallback) {
          this.props.uploadCompletedCallback(file_name, response);
        }
      })
      .catch((error) => {
        console.log('put_case_file Error ' + error.status);
        console.log(error.response);
        if (this.props.uploadErrorCallback) {
          this.props.uploadErrorCallback(file_name, error);
        }
      });
    // aws_api_call.put_rqst(
    //   '/cases/' + case_id + '/' + encodeURIComponent(file_name),
    //   file_data,
    //   (function (cmpnt, case_id, file_name) {
    //     return function (xhr) {
    //       console.log('put_case_file Uploaded ' + file_name + ' Successfully');
    //       if (
    //         cmpnt !== null &&
    //         cmpnt !== undefined &&
    //         cmpnt.props.uploadCompletedCallback !== null &&
    //         cmpnt.props.uploadCompletedCallback !== undefined
    //       ) {
    //         cmpnt.props.uploadCompletedCallback(file_name, xhr);
    //       }
    //     };
    //   })(this, case_id, file_name),
    //   { 'Content-Type': file_type, Accept: file_type },
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       console.log('put_case_file Error ' + xhr.status);
    //       console.log(xhr.response);
    //       if (
    //         cmpnt !== null &&
    //         cmpnt !== undefined &&
    //         cmpnt.props.uploadErrorCallback !== null &&
    //         cmpnt.props.uploadErrorCallback !== undefined
    //       ) {
    //         cmpnt.props.uploadErrorCallback(file_name, xhr);
    //       }
    //     };
    //   })(this),
    //   function (xhr) {
    //     console.log('put_case_file No Data');
    //   },
    //   (function (cmpnt, file_name) {
    //     return function (e) {
    //       console.log('put_case_file Progress ' + (e.loaded / Math.max(e.total, 1)) * 100 + '%');
    //       if (
    //         cmpnt !== null &&
    //         cmpnt !== undefined &&
    //         cmpnt.props.uploadProgressCallback !== null &&
    //         cmpnt.props.uploadProgressCallback !== undefined
    //       ) {
    //         cmpnt.props.uploadProgressCallback(file_name, e);
    //       }
    //     };
    //   })(this, file_name)
    // );
  }

  uploadFile(file) {
    // Upload file to server and add a callback to update the progress bar.

    //if we don't yet have a case id then keep trying every second until we do
    //NOTE: at this point in the code we should ALWAYS have a caseId

    let file_name = file.name;
    let file_type = file.type;
    let is_unique = true;

    // check if the file name is unique
    if (this.props.uniqueCheckCallback !== null && this.props.uniqueCheckCallback !== undefined) {
      is_unique = this.props.uniqueCheckCallback(file_name);
    }

    // handling to prevent the uploading of files whose filenames have already been used
    if (is_unique) {
      //in order to make it clear to the user that an upload is occurring we should immediately output a message
      /*
			toast.success('Starting file upload '+file_name+'...', {
				autoClose:2000,
			});
*/
      this.put_case_file(this.props.caseId, file_name, file_type, file);
      // let reader = new FileReader();
      // reader.addEventListener(
      //   'load',
      //   (function (cmpnt, file_name, file_type) {
      //     return function (loadEvent) {
      //       // let file_data = new Uint8Array(loadEvent.target.result);
      //       cmpnt.put_case_file(cmpnt.props.caseId, file_name, file_type, loadEvent.target.result);
      //     };
      //   })(this, file_name, file_type)
      // );
      // reader.readAsArrayBuffer(file);

      // Timeout needed to make the file browser work, it was too fast and only updating the state of the last image.
      // When we add real uploading, this might not be necessary due to server speeds
      setTimeout(() => {
        this.props.updateFileList(file);
      }, 500);
    } else {
      toast.error(
        'Error: A file with the name ' +
          file_name +
          ' already exists.  If you wish to try uploading this file again, please delete the file of the same name first.  '
      );
    }
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  // `this` is actually the class and the variable gets applied to div.FileSelect
  highlight() {
    this.classList.add('highlight');
  }

  // `this` is actually the class and the variable gets applied to div.FileSelect
  unhighlight() {
    this.classList.remove('highlight');
  }

  handleDrop(e) {
    /*
			The dataTransfer.files function is available in all browsers except Safari for IOS.
			If we find this to be an issue another approach might be warranted,
			but I don't think that anyone will be using this in a clinic on their iphone.
		*/

    //NOTE: the number of files is limited to 2-10 images or one or more zip files by logic in CreateCaseForm::handleFileUploadUpdate
    //which is triggered via callbacks

    const dt = e.dataTransfer;
    const file = dt.files;
    if (file_mimes.includes(file[0].type)) {
      this.handleFiles(file);
    }
  }

  render() {
    return (
      <div id={this.state.dropAreaId} className={`FileSelect`}>
        <i className="icon far fa-arrow-alt-circle-up"></i>
        {this.props.dropInstructions ? (
          this.props.dropInstructions
        ) : (
          <p>
            Drag and Drop files here <br />
            OR
          </p>
        )}
        <input
          type="file"
          id={this.state.fileInputId}
          multiple
          accept={file_mimes.join(',')}
          onChange={(e) => {
            this.handleFiles(e.target.files);
          }}
        />
        <label className="button inverted" htmlFor={this.state.fileInputId}>
          {this.props.browseInstructions ? this.props.browseInstructions : 'Browse Files'}
        </label>
      </div>
    );
  }
}

FileSelect.propTypes = {
  idUniqueIdentifier: PropTypes.string.isRequired,
  updateFileList: PropTypes.func.isRequired,
  uploadProgressCallback: PropTypes.func,
  uploadCompletedCallback: PropTypes.func,
  uploadErrorCallback: PropTypes.func,
  uniqueCheckCallback: PropTypes.func.isRequired,
};

export default FileSelect;
