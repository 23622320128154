/*
This a helper file holding functions that are called throughout the project for session-management
*/

import { ROUTES } from '@router/routes';
import { router } from '@router/root';

export type DecodeTokenInfo = {
  role: string;
  clinic: string;
  email: string;
  account_id: string;
  clinic_name: string;
  session_name: string;
};

// Storage the session token from the login
export function setSessionToken(token: string) {
  // sessionStorage.setItem(`auth-${session_name}`, token);
  sessionStorage.setItem('auth', token);
}

// Get the session token from the login
export function getSessionToken() {
  return sessionStorage.getItem('auth');
}

// This function will log the user out and clear all the session data
export function logout() {
  // validate if the user is currently logged in
  if (getSessionToken()) {
    // remove token
    sessionStorage.removeItem('auth');
  }

  // let login_cookie_names = [
  //   'aws-access-key',
  //   'aws-secret-key',
  //   'aws-security-token',

  //   'visionquest-session-name',
  //   'visionquest-user-role',
  //   'visionquest-user-clinic',
  //   'visionquest-user-email',
  //   'visionquest-clinic-name',
  // ];

  // for (var i = 0; i < login_cookie_names.length; i++) {
  //   document.cookie = login_cookie_names[i] + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // }

  router.navigate(ROUTES.LOGOUT).catch((error) => {
    console.error('Error navigating to login page: ', error);
  });
}
