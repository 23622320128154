import { ReactNode, SelectHTMLAttributes, forwardRef } from 'react';

type SelectFieldProps = SelectHTMLAttributes<HTMLSelectElement> & {
  classNameFieldSet?: string;
  label?: string;
  name: string;
  options: Record<string, string>[] | string[]; // { key: label } // [label, label, label]
  defaultLabel?: string;
  enableEmptyOption?: boolean;
};

/*
 * Select, a dropdown for an array of options
 * forwardRef is used to pass the ref to the input field, since we are using ReactFormHook
 * @props:
 * extends InputHTMLAttributes<HTMLInputElement>
 * - options: the array of options // { key: label }[]
 * - label: the label of the input field
 * - classNameFieldSet: the class of the fieldset
 * - name: the name of the input field (required)
 */

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const {
    id,
    name,
    required,
    options,
    defaultLabel,
    enableEmptyOption,
    classNameFieldSet = ' ',
    className = '',
    label,
    ...rest
  } = props;

  function populateOptionElements() {
    const elements: ReactNode[] = [];
    if (enableEmptyOption) {
      elements.push(
        <option key={0} value={''}>
          {defaultLabel ? defaultLabel : '- Select -'}
        </option>
      );
    }
    // transform the data into option elements
    for (const option of options) {
      if (typeof option === 'string') {
        elements.push(
          <option key={`select-option-${name}-${option}`} value={option}>
            {option}
          </option>
        );
        continue;
      }

      const key = Object.keys(option)[0];
      const label = Object.values(option)[0];

      elements.push(
        <option key={`select-option-${name}-${key}`} value={key}>
          {label}
        </option>
      );
    }
    return elements;
  }

  return (
    <fieldset className={`InputFieldBlock ${classNameFieldSet}`}>
      {label && (
        <label className="input-label" htmlFor={id ?? name}>
          {label}
          {required && ' *'}
        </label>
      )}
      <select
        {...rest}
        ref={ref}
        className={'Select input-field ' + className}
        id={id ?? name}
        name={name}
        required={required}
      >
        {populateOptionElements()}
      </select>
    </fieldset>
  );
});

SelectField.displayName = 'SelectField';
