/*
SignUp - A sign up view with various input fields

A Sign up page consists of InputFieldBlocks and a InputDropdownBlock component
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import InputFieldBlock from '../lib-medical-portal/components/FormInputs/InputFieldBlock.jsx';
import InputDropdownBlock from '../lib-medical-portal/components/FormInputs/InputDropdownBlock.jsx';
import { toast_error } from '@libs/toast-wrappers';
import {
  ERROR_MESSAGE_TRY_AGAIN_EMAIL,
  ERROR_SIGN_UP_REQUEST,
  ERROR_FETCH_COUNTRIES,
  ERROR_SIGN_UP_API_EMAIL_IN_USE,
  ERROR_SIGN_UP_EMAIL_IN_USE,
} from '@utils/messages';
import { ROLES_LABELS } from '@utils/constants';

//internationalization and translation support
import i18n from '@libs/i18n.js';
import { getCountries as requestCountries } from '@api/countries';
import { signUp as signUpRequest } from '@api/auth';

/*
	props (component-level arguments):
		callbackLoginPage: a callback function from the parent to show the Login form
		callbackFormSubmittedPage: a callback function from the parent to show the 'Form-submitted' page
 */
class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryOptionArray: [{ US: 'United States' }, { Canada: 'Canada' }, { Mexico: 'Mexico' }],
    };

    this.getCountries = this.getCountries.bind(this);
  }

  componentDidMount() {
    this.getCountries();
  }

  getCountries() {
    requestCountries().then((countryOptionArray) => {
      this.setState({
        countryOptionArray: countryOptionArray,
      });
    });
  }

  onClickLoginHere() {
    this.props.callbackLoginPage();
  }

  onClickSubmitButton(context) {
    return function (event) {
      event.preventDefault();
      context.submitSignUpRequest(context);
    };
  }

  submitSignUpRequest(context) {
    const name = document.getElementById('fullName').value;
    const clinic = document.getElementById('clinicName').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const address = document.getElementById('streetAddress').value;
    const city = document.getElementById('city').value;
    const provinceState = document.getElementById('province').value;
    const postalZip = document.getElementById('postalCode').value;
    const country = document.getElementById('countryDropdown').value;
    const requestedRole = document.getElementById('roleDropdown').value;

    // adding only required fields to the sign up data
    const signup_data = {
      name,
      email,
      phone,
      city,
      provinceState,
      country,
      requestedRole,
    };
    // adding some optional fields to the sign up data
    if (address) signup_data.address = address;
    if (postalZip) signup_data.postal = postalZip;
    if (clinic) signup_data.clinic = clinic;

    // TODO: remove the following code after the above code is tested
    signUpRequest(signup_data).then((result) => {
      if (result) {
        context.props.callbackFormSubmittedPage();
      }
    });
    // aws_api_call.post_rqst(
    //   '/users/signup',
    //   JSON.stringify(signup_data),
    //   (function (component) {
    //     return function (xhr) {
    //       console.log(xhr);
    //       let response = xhr.response;
    //       let data = JSON.parse(response);
    //       if (data.hasOwnProperty('result') && data['result'] === ERROR_SIGN_UP_API_EMAIL_IN_USE) {
    //         toast_error(ERROR_SIGN_UP_EMAIL_IN_USE);
    //       } else {
    //         context.props.callbackFormSubmittedPage();
    //       }
    //     };
    //   })(this),
    //   {},
    //   function (xhr) {
    //     console.log('Error during sign up - status: ' + xhr.status);
    //     console.log(xhr.response);
    //     toast_error(ERROR_SIGN_UP_REQUEST + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //   }
    // );
  }

  render() {
    return (
      <div className="sign-up-form-row">
        <div className="page-title">{i18n.t('signup-title')}</div>
        <form className="sign-up-form" onSubmit={this.onClickSubmitButton(this)}>
          <InputFieldBlock
            title={i18n.t('signup-full-name') + '*'}
            fieldName="fullName"
            titleClass="sign-up-field-label"
            fieldClass="sign-up-field-input"
            fieldRequired={true}
          />
          <InputFieldBlock
            title={i18n.t('signup-clinic-name')}
            fieldName="clinicName"
            titleClass="sign-up-field-label"
            fieldClass="sign-up-field-input"
            fieldRequired={false}
          />
          <InputDropdownBlock
            title={i18n.t('signup-role') + '*'}
            dropdownId="roleDropdown"
            titleClassName="sign-up-field-label"
            dropdownClass="sign-up-field-input"
            dropdownRequired={true}
            optionData={ROLES_LABELS}
            callback={this.handleDropdownChange}
          />
          <div className="grid-container full">
            <div className="grid-x sign-up-form-grid-x">
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputFieldBlock
                  title={i18n.t('login-email') + '*'}
                  fieldName="email"
                  titleClass="sign-up-field-label"
                  fieldClass="sign-up-field-input"
                  fieldType="email"
                  fieldRequired={true}
                />
              </div>
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputFieldBlock
                  title={i18n.t('signup-phone') + '*'}
                  fieldName="phone"
                  titleClass="sign-up-field-label"
                  fieldClass="sign-up-field-input"
                  fieldRequired={true}
                />
              </div>
            </div>
          </div>
          <InputFieldBlock
            title={i18n.t('signup-address') + '*'}
            fieldName="streetAddress"
            titleClass="sign-up-field-label"
            fieldClass="sign-up-field-input"
            fieldRequired={true}
          />
          <div className="grid-container full">
            <div className="grid-x sign-up-form-grid-x">
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputFieldBlock
                  title={i18n.t('signup-city') + '*'}
                  fieldName="city"
                  titleClass="sign-up-field-label"
                  fieldClass="sign-up-field-input"
                  fieldRequired={true}
                />
              </div>
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputFieldBlock
                  title={i18n.t('signup-state') + '*'}
                  fieldName="province"
                  titleClass="sign-up-field-label"
                  fieldClass="sign-up-field-input"
                  fieldRequired={true}
                />
              </div>
            </div>
          </div>
          <div className="grid-container full">
            <div className="grid-x sign-up-form-grid-x">
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputFieldBlock
                  title={i18n.t('signup-postal') + '*'}
                  fieldName="postalCode"
                  titleClass="sign-up-field-label"
                  fieldClass="sign-up-field-input"
                  fieldRequired={true}
                />
              </div>
              <div className="cell small-12 large-6 sign-up-form-grid-cell">
                <InputDropdownBlock
                  title={i18n.t('signup-country') + '*'}
                  dropdownId="countryDropdown"
                  titleClassName="sign-up-field-label"
                  dropdownClass="sign-up-field-input"
                  dropdownRequired={true}
                  optionData={this.state.countryOptionArray}
                  callback={this.handleDropdownChange}
                />
              </div>
            </div>
          </div>
          <div className="indicates-required-field">{i18n.t('signup-required-legend')}</div>
          <div className="login-button-row">
            <button type="submit" className="button">
              {i18n.t('signup-submit')}
            </button>
            <div className="wrapper-new-user-sign-up">
              <Trans i18nKey="signup-have-account" className="inline-block">
                <p className="signup-cta inline-block-mobile-margin">Already have an account?</p>
              </Trans>
              <p
                className="inline-block-mobile-margin login-page-blue-clickable-text"
                onClick={() => this.onClickLoginHere()}
              >
                {i18n.t('changepass-login')}
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SignUp.propTypes = {
  callbackLoginPage: PropTypes.func.isRequired,
  callbackFormSubmittedPage: PropTypes.func.isRequired,
};

export default SignUp;
