import { useEffect, useCallback, useState } from 'react';

import { getProfile } from '@api/user-profile';
import { useUserProfile } from '@states/user-profile';
import { errorlog } from '@libs/error-logs';

/**
 * Fetches the user profile from the API and sets it in the global state.
 */
export function useGetUserProfile() {
  const [loading, setLoading] = useState(false);
  const { email, setProfile } = useUserProfile((state) => ({
    email: state.profile.email,
    setProfile: state.setUserProfile,
  }));

  const getUserProfile = useCallback(async () => {
    try {
      // avoid fetching profile if email is empty
      if (!email) return;
      setLoading(true);

      const profile = await getProfile(email);
      if (profile) {
        setProfile(profile);
      }

      setLoading(false);
    } catch (error) {
      errorlog(error as Error, 'src/hooks/use-get-user-profile.ts');
      setLoading(false);
    }
  }, [email, setProfile]);

  useEffect(() => {
    void getUserProfile();
  }, [getUserProfile]);

  return { loading, refresh: getUserProfile };
}
