/*
GlobalHeader, a header component that exists at the top of all pages
*/

//internationalization and translation support
import i18n from '@libs/i18n.js';

import { useCurrentClinic } from '@hooks/use-current-clinic';
import { LanguageSwitcher } from '@components/language-switcher';

export const GlobalHeader = () => {
  const { clinicName } = useCurrentClinic();
  return (
    <div className="global-header">
      <div className="small-8 medium-9 large-10 cell">
        <div className="your-clinic-name-wrapper">
          <div className="clinic-name-wrapper">
            <div className="your-clinic">{i18n.t('header-your-clinic')}</div>
            <h1 className="clinic-name">{clinicName}</h1>
          </div>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};
