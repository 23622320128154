import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@router/routes';
import { useUserProfile } from '@states/user-profile';
import { devlog } from '@libs/dev-logs';
import { parse_cookies } from '../react-utils/src/libformat';

export function LogoutPage() {
  const { profile, clear } = useUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = parse_cookies();
    const hasLoginCookies = cookies?.['aws-access-key'];

    // if the user has login cookies, get this url by error
    if (hasLoginCookies) {
      navigate(ROUTES.CASES);
      return;
    }

    // remove the state
    if (profile?.email) {
      devlog('clearing user profile');
      clear();
    }
  });

  return <div className="flex-full-center">Closing session...</div>;
}
