import { useEffect, useState } from 'react';
import { parse_cookies } from '../react-utils/src/libformat';

export function useCurrentClinic() {
  const [clinicName, setClinicName] = useState('-');

  useEffect(() => {
    const cookies = parse_cookies();

    if (Object.prototype.hasOwnProperty.call(cookies, 'visionquest-clinic-name')) {
      setClinicName(cookies['visionquest-clinic-name'] as string);
    }
  }, []);

  return { clinicName };
}
