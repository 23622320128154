/*
CreateCaseForm - A form to create a new case
	A CreateCaseForm consists of InputFieldBlocks, InputDropdownBlocks, a FileManager, InputTextareas, and InputRadioBlocks

*/

import React from 'react';
import PropTypes from 'prop-types';
import { toast_error } from '@libs/toast-wrappers';

import { ERROR_CREATING_CASE, ERROR_MESSAGE_TRY_AGAIN_EMAIL } from '@utils/messages';

import { parse_cookies } from '../react-utils/src/libformat';

import { Case, CASE_STATUS } from '../models/Case';

import InputFieldBlock from '../lib-medical-portal/components/FormInputs/InputFieldBlock.jsx';
import InputDropdownBlock from '../lib-medical-portal/components/FormInputs/InputDropdownBlock.jsx';
import FileManager from '../lib-medical-portal/components/FileManager/FileManager.jsx';
import InputTextarea from '../lib-medical-portal/components/FormInputs/InputTextarea.jsx';
import InputRadioBlock from '../lib-medical-portal/components/FormInputs/InputRadioBlock.jsx';

//internationalization and translation support
import { Trans } from 'react-i18next';
import i18n from '@libs/i18n.js';
import { deleteFile, postCase, postCaseInit } from '@api/cases';

/*
props (component-level arguments):
	caseId: the id of the new case
	newCaseCallback: a onsubmit callback to pass form data to the parent component when the form is completed but files have not yet been uploaded
	newCaseProcessingCallback: a onsubmit callback to pass form data to the parent component when the form is completed and files have been uploaded
	progressUpdateCallback: a callback that will trigger after every polling response
	processingCompleteCallback: a callback that will trigger when the processing has completed, and will provide the result status as an argument
	minImageCount: the minimum number of images that are required before a submit action can be completed (default 2)
	formTitle: the title of the form; defaults to "Create a New Case"
*/
class CreateCaseForm extends React.Component {
  constructor(props) {
    super(props);
    //The state names here must match the ID of the fields in the form
    this.state = {
      submitDisabled: true,
      caseFileType: 'zip',
      createdByInput: '',
      siteIdInput: '',
      cameraDDL: '',
      patientIdInput: '',
      patientNameInput: '',
      genderDDL: '',
      raceDDL: '',
      hasDiabetesRadio: false,
      yearDiagnosedDDL: '',
      diabetesTypeDDL: '',
      eyeDiseaseHistoryInput: '',
      additionalNotesInput: '',
    };

    this.eyestar_init = this.eyestar_init.bind(this);
    this.eyestar_processing_progress = this.eyestar_processing_progress.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFileUploadUpdate = this.handleFileUploadUpdate.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleBoolRadioChange = this.handleBoolRadioChange.bind(this);
  }

  create_case(newCase, successCallback) {
    //NOTE: we have to translate data fields here because our model's internal data field names don't match the data field names the eyestar API now expects
    let caseData = {};
    caseData['patientID'] = newCase.getPatientId();
    caseData['patientName'] = newCase.getPatientName();
    caseData['site_id'] = newCase.getSiteId();
    caseData['camera'] = newCase.getCamera();
    caseData['gender'] = newCase.getGender();
    caseData['ethnicity'] = newCase.getRace();
    caseData['hasDiabetes'] = newCase.getHasDiabetes();
    caseData['diabetesType'] = newCase.getDiabetesType();
    caseData['yearDiagnosed'] = newCase.getYearDiagnosed();
    caseData['eyeHistory'] = newCase.getEyeDiseaseHistory();
    caseData['notes'] = newCase.getNotes();

    //remove empty and null data so as to avoid clutter and prevent database integrity errors
    let keys = Object.keys(caseData);
    for (var i = 0; i < keys.length; i++) {
      if (caseData[keys[i]] === null || caseData[keys[i]] === '') {
        delete caseData[keys[i]];
        i = 0;
        keys = Object.keys(caseData);
      }
    }

    //POST /cases
    postCase(caseData).then((response) => {
      if (response.hasOwnProperty('case')) {
        console.log('create_case Success; new case id is ' + response['case']);
        let out_elem = document.getElementById('case-id');
        if (out_elem !== null) {
          out_elem.innerText = response['case'];
        }
        newCase.caseId = response['case'];
        successCallback(this, newCase);
      } else if (response.hasOwnProperty('Error')) {
        toast_error(ERROR_CREATING_CASE + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
      }
    });
    // TODO: we update the request, remove the comments when it gets fully tested
    // aws_api_call.post_rqst(
    //   '/cases',
    //   JSON.stringify(caseData),
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       let response = xhr.response;
    //       let data = JSON.parse(response);

    //       if (data.hasOwnProperty('case')) {
    //         console.log('create_case Success; new case id is ' + data['case']);
    //         let out_elem = document.getElementById('case-id');
    //         if (out_elem !== null) {
    //           out_elem.innerText = data['case'];
    //         }
    //         newCase.caseId = data['case'];
    //         successCallback(cmpnt, newCase);
    //       } else if (data.hasOwnProperty('Error')) {
    //         toast_error(ERROR_CREATING_CASE + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //       }
    //     };
    //   })(this),
    //   { 'Content-Type': 'application/json' },
    //   function (xhr) {
    //     console.log('create_case Error ' + xhr.status);
    //     console.log(xhr.response);
    //     toast_error(ERROR_CREATING_CASE + ' ' + ERROR_MESSAGE_TRY_AGAIN_EMAIL);
    //   },
    //   function (xhr) {
    //     console.log('create_case No Data');
    //   }
    // );
  }

  eyestar_init(case_type, quality = 1) {
    //POST /cases/{case_id}?type={case_type}&quality={quality}
    postCaseInit(this.props.caseId, case_type, quality).then((response) => {
      console.log('eyestar_init Success!');
      console.log(response); //debug
      this.eyestar_processing_progress();
    });
    // TODO: we update the request, remove the comments when it gets fully tested
    // aws_api_call.post_rqst(
    //   '/cases/' + this.props.caseId + '?type=' + case_type + '&quality=' + quality,
    //   '',
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       console.log('eyestar_init Success!');
    //       let response = xhr.response;
    //       console.log(response); //debug

    //       cmpnt.eyestar_processing_progress();
    //     };
    //   })(this),
    //   { 'Content-Type': '' },
    //   function (xhr) {
    //     console.log('eyestar_init Error ' + xhr.status);
    //     console.log(xhr.response);
    //   }
    // );
  }

  eyestar_processing_progress() {
    //GET /cases/{case_id}?type=score
    getCase(this.props.caseId, 'score').then((result) => {
      switch (result) {
        //if this is one of the various "in progress" states
        //then continue to try until a real result is obtained
        case CASE_STATUS.IN_PROGRESS:
        case CASE_STATUS.PENDING:
        case CASE_STATUS.DATA_NOT_PROCESSED:
          if (this.props.progressUpdateCallback) {
            this.props.progressUpdateCallback(result);
          }
          setTimeout(
            (function (cmpnt) {
              return function () {
                cmpnt.eyestar_processing_progress();
              };
            })(this),
            5000
          );
          break;
        //if this is a "result" case then trigger the complete callback and stop checking this endpoint
        default:
          if (this.props.processingCompleteCallback) {
            this.props.processingCompleteCallback(result);
          }
          break;
      }
    });
    // TODO: we update the request, remove the comments when it gets fully tested
    // aws_api_call.get_rqst(
    //   '/cases/' + this.props.caseId + '?type=score',
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       let response = JSON.parse(xhr.response);
    //       console.log(response); //debug

    //       switch (response['result']) {
    //         //if this is one of the various "in progress" states
    //         //then continue to try until a real result is obtained
    //         case CASE_STATUS.IN_PROGRESS:
    //         case CASE_STATUS.PENDING:
    //         case CASE_STATUS.DATA_NOT_PROCESSED:
    //           if (cmpnt.props.progressUpdateCallback) {
    //             cmpnt.props.progressUpdateCallback(response['result']);
    //           }
    //           setTimeout(
    //             (function (cmpnt) {
    //               return function () {
    //                 cmpnt.eyestar_processing_progress();
    //               };
    //             })(cmpnt),
    //             5000
    //           );
    //           break;
    //         //if this is a "result" case then trigger the complete callback and stop checking this endpoint
    //         default:
    //           if (cmpnt.props.processingCompleteCallback) {
    //             cmpnt.props.processingCompleteCallback(response['result']);
    //           }
    //           break;
    //       }
    //     };
    //   })(this),
    //   {},
    //   function (xhr) {
    //     console.log('eyestar_processing_progress Error ' + xhr.status);
    //     console.log(xhr.response);
    //   }
    // );
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let newCase = new Case();

    //when editing an existing case, we use the case object given instead of the form fields
    if (
      this.props.caseObj !== undefined &&
      this.props.caseObj !== null &&
      this.props.caseObj.getCaseId() === this.props.caseId
    ) {
      newCase = this.props.caseObj;
    } else {
      newCase.setCaseId(this.props.caseId);
      newCase.setClinicId(this.props.clinicId);
      newCase.setCreatedBy(this.state.createdByInput);
      newCase.setPatientId(this.state.patientIdInput);
      newCase.setPatientName(this.state.patientNameInput);
      newCase.setSiteId(this.state.siteIdInput);
      newCase.setCamera(this.state.cameraDDL);
      newCase.setGender(this.state.genderDDL);
      newCase.setRace(this.state.raceDDL);
      newCase.setHasDiabetes(this.state.hasDiabetesRadio);
      newCase.setYearDiagnosed(this.state.yearDiagnosedDDL);
      newCase.setDiabetesType(this.state.diabetesTypeDDL);
      newCase.setEyeDiseaseHistory(this.state.eyeDiseaseHistoryInput);
      newCase.setNotes(this.state.additionalNotesInput);
      //TODO The code below will be needed in the future, but is not needed currently.
      // newCase.setResult();
      // newCase.setStatus();
      // newCase.setImageQuantity();
      // newCase.setCasePdfS3Path();
      // let paths = [];
      // newCase.setRetinalImagePaths(paths);
    }

    //if a case hasn't yet been created then do that first
    if (this.props.caseId === null) {
      this.create_case(newCase, function (cmpnt, newCase) {
        cmpnt.setState({
          newCaseId: newCase.getCaseId(),
        });
        if (cmpnt.props.newCaseCallback) {
          //this shows the in progress dialogue
          cmpnt.props.newCaseCallback(newCase);
        }
      });

      //if a case has already been created then start the processing now
      //(we know files were uploaded because the submit button was enabled)
    } else {
      if (this.props.newCaseProcessingCallback) {
        //this shows the in progress dialogue
        this.props.newCaseProcessingCallback(newCase);
      }
      //reset the form for any subsequent requests
      document.getElementById(this.props.formId).reset();

      //determine type here based on uploaded file types and start processing
      this.eyestar_init(this.state.caseFileType);

      //once processing is started we re-set the form for the next case to be processed
      this.setState({
        submitDisabled: true,
      });
    }

    return false;
  }

  handleFileUploadUpdate(fileList, expectedFileCount) {
    let caseFileType = 'auto';
    let submitDisabled = true;

    //the type of the report as a whole is determined by the type of the first file
    if (fileList.length > 0) {
      switch (fileList[0].type) {
        case 'image/jpeg':
          caseFileType = 'jpg';
          break;
        case 'application/dicom':
          caseFileType = 'dcm';
          break;
        case 'application/zip':
          caseFileType = 'zip';
          break;
        default:
          caseFileType = 'auto';
          break;
      }
    }

    //if a zip file is given then only one file is needed
    if (caseFileType === 'zip') {
      submitDisabled = fileList.length < 1 || fileList.length < expectedFileCount;
      //otherwise at least two files are required
    } else {
      submitDisabled =
        fileList.length < this.props.minImageCount || fileList.length < expectedFileCount;
    }

    this.setState({
      submitDisabled: submitDisabled,
      caseFileType: caseFileType,
    });
  }

  handleFileDelete(fileMeta) {
    //DELETE /cases/{case_id}/{file_name}
    deleteFile(this.props.caseId, fileMeta.name).then((response) => {
      console.log('handleFileDelete Success!');
      console.log(response); //debug
    });
    // TODO: we update the request, remove the comments when it gets fully tested
    // aws_api_call.delete_rqst(
    //   '/cases/' + this.props.caseId + '/' + fileMeta.name,
    //   (function (cmpnt) {
    //     return function (xhr) {
    //       console.log('handleFileDelete Success!');
    //       let response = JSON.parse(xhr.response);
    //       console.log(response); //debug
    //     };
    //   })(this)
    // );
  }

  handleInputFieldChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDropdownChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleBoolRadioChange(event) {
    if (event.target.value === 'yes') {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  }

  render() {
    const genderArray = [
      { male: i18n.t('gender-male') },
      { female: i18n.t('gender-female') },
      { other: i18n.t('default-other') },
    ];

    const race_ethnicityArray = [
      { african: i18n.t('race-african') },
      { asian: i18n.t('race-asian') },
      { caucasian: i18n.t('race-caucasian') },
      { hispanic: i18n.t('race-hispanic') },
      { other: i18n.t('default-other') },
    ];

    let yearArray = [];
    const currentYear = new Date().getFullYear();
    for (let x = currentYear; x > currentYear - 100; x--) {
      yearArray.push({ [x]: x });
    }

    const diabetesTypeArray = [{ type1: 'Type 1' }, { type2: 'Type 2' }, { type3: 'Type 3' }];

    const yesnoArray = [{ yes: i18n.t('yes') }, { no: i18n.t('no') }];

    let cookies = parse_cookies();
    let createdBy = '';
    if (cookies.hasOwnProperty('visionquest-user-email')) {
      createdBy = cookies['visionquest-user-email'];
    }

    let drop_instructions = (
      <Trans i18nKey="case-create-drop-instructions">
        <p>
          Drag and Drop files here <br />
          OR
        </p>
      </Trans>
    );
    let browse_instructions = i18n.t('case-create-browse-files');

    return (
      <div className={`CreateCaseForm cell small-12 medium-9`}>
        <h2 className="title">{this.props.formTitle}</h2>
        {this.props.caseId !== null ? (
          <p className="case-id fineprint">
            {i18n.t('case-create-creating-id') + ' ' + this.props.caseId}
          </p>
        ) : (
          ''
        )}
        <form id={this.props.formId} onSubmit={this.handleFormSubmit}>
          {this.props.caseId !== null ? (
            <FileManager
              label={i18n.t('case-create-retinal-images')}
              direction={i18n.t('case-create-image-directions')}
              idUniqueIdentifier="createCaseFileManager"
              uploadCallback={this.handleFileUploadUpdate}
              deleteCallback={this.handleFileDelete}
              caseId={this.props.caseId}
              dropInstructions={drop_instructions}
              browseInstructions={browse_instructions}
            />
          ) : (
            <div className="form-field-wrapper">
              <div className="main-form-field-wrapper">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-12">
                    <InputFieldBlock
                      title={i18n.t('case-create-created-by')}
                      titleClassName="created-by-label"
                      fieldName="createdByInput"
                      fieldHint="..."
                      fieldClass="created-by-input"
                      fieldRequired={false}
                      fieldValue={createdBy}
                      callback={this.handleInputFieldChange}
                      disabled={true}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputFieldBlock
                      title={i18n.t('case-create-site-id')}
                      titleClassName="site-id-label"
                      fieldName="siteIdInput"
                      fieldHint="..."
                      fieldClass="site-id-input"
                      fieldRequired={false}
                      callback={this.handleInputFieldChange}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputDropdownBlock
                      title={i18n.t('case-create-camera')}
                      dropdownId="cameraDDL"
                      optionData={this.props.cameraOptionArray}
                      callback={this.handleDropdownChange}
                      defaultSelectText={i18n.t('default-select')}
                    />
                  </div>
                </div>
                <div className="grid-x grid-padding-x">
                  <div className="cell small-12">
                    <h3 className="subtitle">{i18n.t('case-create-patient-info')}</h3>
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputFieldBlock
                      title={i18n.t('case-create-patient-id')}
                      titleClassName="patient-id-label"
                      fieldName="patientIdInput"
                      fieldHint="..."
                      fieldClass="patient-id-input"
                      fieldRequired={true}
                      callback={this.handleInputFieldChange}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputFieldBlock
                      title={i18n.t('case-create-patient-name')}
                      titleClassName="patient-name-label"
                      fieldName="patientNameInput"
                      fieldHint="..."
                      fieldClass="patient-name-input"
                      fieldRequired={false}
                      callback={this.handleInputFieldChange}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputDropdownBlock
                      title={i18n.t('case-create-gender')}
                      dropdownId="genderDDL"
                      optionData={genderArray}
                      callback={this.handleDropdownChange}
                      defaultSelectText={i18n.t('default-select')}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputDropdownBlock
                      title={i18n.t('case-create-race')}
                      dropdownId="raceDDL"
                      optionData={race_ethnicityArray}
                      callback={this.handleDropdownChange}
                      defaultSelectText={i18n.t('default-select')}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputRadioBlock
                      title={i18n.t('case-create-diabetes')}
                      radioId="hasDiabetesRadio"
                      data={yesnoArray}
                      radioName="hasDiabetesRadio"
                      extraClasses="row-radio-buttons"
                      callback={this.handleBoolRadioChange}
                    />
                  </div>
                  <div className="cell small-12 medium-6">
                    <InputDropdownBlock
                      title={i18n.t('case-create-year-diagnosed')}
                      dropdownId="yearDiagnosedDDL"
                      optionData={yearArray}
                      callback={this.handleDropdownChange}
                      defaultSelectText={i18n.t('default-select')}
                    />
                  </div>
                  <div className="cell small-12">
                    <InputDropdownBlock
                      title={i18n.t('case-create-diabetes-type')}
                      dropdownId="diabetesTypeDDL"
                      optionData={diabetesTypeArray}
                      callback={this.handleDropdownChange}
                      defaultSelectText={i18n.t('default-select')}
                    />
                  </div>
                  <div className="cell small-12">
                    <InputFieldBlock
                      title={i18n.t('case-create-eye-disease-hist')}
                      titleClassName="eye-disease-history-label"
                      fieldName="eyeDiseaseHistoryInput"
                      fieldHint="..."
                      fieldClass="eye-disease-history-input"
                      fieldRequired={false}
                      callback={this.handleInputFieldChange}
                    />
                  </div>
                  <div className="cell small-12">
                    <InputTextarea
                      title={i18n.t('case-create-notes')}
                      titleClassName="additional-notes-label"
                      fieldName="additionalNotesInput"
                      fieldHint="..."
                      fieldClass="additional-notes-input"
                      fieldRequired={false}
                      callback={this.handleInputFieldChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="cell small-12">
            {this.props.caseId === null ? (
              <input
                disabled={false}
                className="button"
                type="submit"
                value={i18n.t('case-create-continue')}
              />
            ) : (
              <input
                disabled={this.state.submitDisabled}
                className="button"
                type="submit"
                value={i18n.t('default-submit')}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

CreateCaseForm.defaultProps = {
  caseId: null,
  clinicId: null,
  minImageCount: 2,
  formTitle: 'Create a New Case',
  cameraOptionArray: [{ opt1: 'Option 1' }, { opt2: 'Option 2' }, { opt3: 'Option 3' }],
};

CreateCaseForm.propTypes = {
  caseId: PropTypes.string,
  clinicId: PropTypes.string,
  newCaseCallback: PropTypes.func,
  newCaseProcessingCallback: PropTypes.func,
  progressUpdateCallback: PropTypes.func,
  processingCompleteCallback: PropTypes.func,
  minImageCount: PropTypes.number,
  formTitle: PropTypes.string,
};

export default CreateCaseForm;
