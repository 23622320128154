import { createRoutesFromElements, createBrowserRouter, Route } from 'react-router-dom';

// components
import Login from '@components/Login';
import ChangePass from '@components/ChangePass';
import KitchenSink from '@components/KitchenSink';
import Dashboard from '@components/Dashboard';
import Cases from '@components/Cases';
import Users from '@components/Users';
import Billing from '@components/Billing';
import LegalDoc from '@components/LegalDoc';
import { ProfilePage } from '@components/profile-page';
import { LogoutPage } from '@components/logout-page';
import { GradingPage } from '@components/grading-page';
import { GradeCase } from '@components/grading/grade-case';

// Layouts for each page we want to render
import { EmptyLayoutAuth } from '@components/auth/empty-layout-auth';
import { RouterWrapper } from '@components/navigation/router-wrapper';

import { REDIRECT_ROUTES, ROUTES } from './routes';
import { MainLayout } from '@components/main-layout';

// utils
import { authRouteLoader, protectedLoader, gradeLoader, redirectHomeLoader } from './loaders';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RouterWrapper />}>
      {/* AUTH ROUTES */}
      <Route path="auth" element={<EmptyLayoutAuth />}>
        <Route path={ROUTES.LOGIN} element={<Login />} loader={authRouteLoader} />
        <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePass />} loader={authRouteLoader} />
        <Route path={ROUTES.NEW_PASSWORD} element={<ChangePass />} loader={authRouteLoader} />
      </Route>
      {/* MAIN ROUTES */}
      <Route path="" Component={MainLayout}>
        <Route index element={<Dashboard />} loader={protectedLoader} />
        <Route path={ROUTES.CASES} element={<Cases />} loader={protectedLoader} />
        <Route path={ROUTES.GRADING} element={<GradingPage />} loader={protectedLoader}>
          <Route path={`${ROUTES.GRADING}/:caseId`} element={<GradeCase />} loader={gradeLoader} />
        </Route>
        <Route path={ROUTES.BILLING} element={<Billing />} loader={protectedLoader} />
        <Route path={ROUTES.USERS} element={<Users />} loader={protectedLoader} />
        <Route path={ROUTES.LEGAL} element={<LegalDoc />} loader={protectedLoader} />
        <Route path={ROUTES.KITCHEN_SINK} element={<KitchenSink />} loader={protectedLoader} />
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} loader={protectedLoader} />
        <Route path={ROUTES.LOGOUT} element={<LogoutPage />} loader={protectedLoader} />
      </Route>
      {/* Redirect Routes */}
      {REDIRECT_ROUTES.map((route) => (
        <Route key={route} path={route} element={<></>} loader={redirectHomeLoader} />
      ))}
    </Route>
  )
);
