import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { SignGradeForm } from '@hooks/use-grade-form-logic';

import { RadioGroup } from '@components/form/radio-group';
import { RadioGroupOption } from '@components/form/radio-group-opt';
import { InputField } from '@components/form/input-field';
import { CheckboxField } from '@components/form/checkbox-field';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@components/form/dropdown';
import { GradeFormSubQuestions } from './grade-form-sub-questions';

type GradeFormMainQuestionsProps = {
  register: UseFormRegister<SignGradeForm>;
  disabled?: boolean;
  watch: UseFormWatch<SignGradeForm>;
  setValue: UseFormSetValue<SignGradeForm>;
};

/**
 * The main questions for the grading form
 */
export function GradeFormMainQuestions(props: GradeFormMainQuestionsProps) {
  const { register, disabled, watch, setValue } = props;
  const { t } = useTranslation();

  const otherDiseaseNoneValue = watch('otherDiseaseNone_bool');
  const otherDiseasesValues = JSON.stringify(
    watch([
      'otherDiseaseCustom_bool',
      'otherDiseaseConcernForGlaucoma_bool',
      'otherDiseaseMacularDrusen_bool',
      'otherDiseaseMacularPigmentaryDisturbance_bool',
      'otherDiseaseEpiretinalMembrane_bool',
      'otherDiseaseChoroidalLesion_bool',
    ])
  );

  const enableOtherCustomString = watch('otherDiseaseCustom_bool');
  const enableFollowUp = useMemo(() => {
    const list = JSON.parse(otherDiseasesValues) as boolean[];
    return list.some((val) => val);
  }, [otherDiseasesValues]);

  const otherSelectLabel = useMemo(() => {
    const list = JSON.parse(otherDiseasesValues) as boolean[];
    const isAnyOneSelected = list.some((val) => val);

    // if no one was selected yet "default value"
    if (!isAnyOneSelected && !otherDiseaseNoneValue) {
      return t('default-select');
    }

    // when none selected
    if (otherDiseaseNoneValue) {
      return t('grade-question-other-diseases-options.none');
    }

    const isOtherSelected = list[0];
    const otherLabels = [
      '',
      t('grade-question-other-diseases-options.other-concern-for-glaucoma'),
      t('grade-question-other-diseases-options.other-macular-drusen'),
      t('grade-question-other-diseases-options.other-macular-pigmentary'),
      t('grade-question-other-diseases-options.other-epiretinal'),
      t('grade-question-other-diseases-options.other-choroidal'),
    ];

    const isMultiSelected = list.filter((val) => val).length > 1;
    if (isMultiSelected) {
      return `Selected: ${otherLabels
        .filter((_, index) => list[index] && index !== 0)
        .join(', ')} ${isOtherSelected ? 'and other' : ''}`;
    }

    if (isOtherSelected) {
      return t('grade-question-other-diseases-options.other');
    }

    return otherLabels[list.indexOf(true)];
  }, [otherDiseaseNoneValue, otherDiseasesValues, t]);

  // when we update the otherDiseaseCustom_string to "other" we need to reset the otherDiseaseCustomInput_string
  useEffect(() => {
    if (!enableOtherCustomString) {
      setValue('otherDiseaseCustomInput_string', '');
    }
  }, [enableOtherCustomString, setValue]);

  // when the user selects the option "None" on the other disease, we need to unselect the other options
  useEffect(() => {
    if (otherDiseaseNoneValue) {
      setValue('otherDiseaseCustom_bool', false);
      setValue('otherDiseaseConcernForGlaucoma_bool', false);
      setValue('otherDiseaseMacularDrusen_bool', false);
      setValue('otherDiseaseMacularPigmentaryDisturbance_bool', false);
      setValue('otherDiseaseEpiretinalMembrane_bool', false);
      setValue('otherDiseaseChoroidalLesion_bool', false);
    }
  }, [otherDiseaseNoneValue, setValue]);

  // if the user selects any other option, we need to unselect the "None" option
  useEffect(() => {
    const list = JSON.parse(otherDiseasesValues) as boolean[];
    if (list.some((val) => val)) {
      setValue('otherDiseaseNone_bool', false);
    }
  }, [otherDiseasesValues, setValue]);

  return (
    <div className="grid grid-cols-1 gap-x-4 ">
      {/* QUESTION READABLE IMAGE QUALITY */}
      <RadioGroup label={t('grade-question-readable-image-quality')} className="mb-8">
        <RadioGroupOption
          label={t('yes')}
          id="readable-yes"
          value="yes"
          required
          disabled={disabled}
          {...register('readable_str')}
        />
        <RadioGroupOption
          label={t('no')}
          id="readable-no"
          value="no"
          required
          disabled={disabled}
          {...register('readable_str')}
        />
      </RadioGroup>

      {/* QUESTION EVIDENCE OF DME */}
      <RadioGroup
        label={t('grade-question-evidence-of-edema')}
        className="mb-8"
        helper={{
          text: t('grade-question-evidence-of-edema-helper'),
          link: 'https://docs.google.com/document/d/11bCeB1m3-tiuJeOPKu7qpAzPT5--uS_6tZ5-l0IeANU/edit?pli=1',
        }}
      >
        <RadioGroupOption
          label={t('yes')}
          id="edema-yes"
          value="yes"
          required
          disabled={disabled}
          {...register('evidenceOfDme_str')}
        />
        <RadioGroupOption
          label={t('no')}
          id="edema-no"
          value="no"
          required
          disabled={disabled}
          {...register('evidenceOfDme_str')}
        />
      </RadioGroup>

      {/* QUESTION DRGRADE */}
      <RadioGroup
        label={t('grade-question-drgrade-scale')}
        className="mb-8"
        helper={{
          text: t('grade-question-drgrade-scale-helper'),
          link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8297841/',
        }}
      >
        <RadioGroupOption
          label={t('grade-options-drgrade-scale.none')}
          id="drgrade-none"
          value="none"
          required
          disabled={disabled}
          {...register('drgrade_string')}
        />
        <RadioGroupOption
          label={t('grade-options-drgrade-scale.mild')}
          id="drgrade-mild"
          value="mild"
          required
          disabled={disabled}
          {...register('drgrade_string')}
        />
        <RadioGroupOption
          label={t('grade-options-drgrade-scale.moderate')}
          id="drgrade-moderate"
          value="moderate"
          required
          disabled={disabled}
          {...register('drgrade_string')}
        />
        <RadioGroupOption
          label={t('grade-options-drgrade-scale.severe')}
          id="drgrade-severe"
          value="severe"
          required
          disabled={disabled}
          {...register('drgrade_string')}
        />
        <RadioGroupOption
          label={t('grade-options-drgrade-scale.proliferative')}
          id="drgrade-proliferative"
          value="proliferative"
          required
          disabled={disabled}
          {...register('drgrade_string')}
        />
      </RadioGroup>

      {/* Secondary questions, based on the main answers */}
      <GradeFormSubQuestions
        register={register}
        watch={watch}
        setValue={setValue}
        disabled={disabled}
      />

      {/* QUESTION REFERRAL */}
      <RadioGroup
        label={t('grade-question-referral')}
        className="mb-8"
        helper={{
          text: t('grade-question-referral-helper'),
          link: 'https://grades.vitazi.ai/Diabetic%20Retinopathy%20PPP_2022%20Update.pdf#page=25',
        }}
      >
        <RadioGroupOption
          label={t('grade-options-referral.now')}
          id="referral-none"
          value="0_months"
          required
          disabled={disabled}
          {...register('referral_string')}
        />
        <RadioGroupOption
          label={t('grade-options-referral.return-6-to-12-mo')}
          id="referral-6to12months"
          value="6-12_months"
          required
          disabled={disabled}
          {...register('referral_string')}
        />
        <RadioGroupOption
          label={t('grade-options-referral.return-12-mo')}
          id="referral-12months"
          value="12_months"
          required
          disabled={disabled}
          {...register('referral_string')}
        />
      </RadioGroup>

      {/* QUESTION OTHER DISEASE */}
      <label htmlFor="other-disease" className="input-label">
        {t('grade-question-other-disease')}
      </label>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={disabled} asChild>
          <button
            id="other-disease"
            type="button"
            className="Select input-field text-start"
            disabled={disabled}
          >
            {otherSelectLabel}
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="max-w-60 md:max-w-96 md:min-w-60 flex flex-col"
        >
          <CheckboxField
            className="p-1"
            disabled={disabled}
            {...register('otherDiseaseCustom_bool')}
            label={t('grade-question-other-diseases-options.other')}
          />
          <CheckboxField
            className="p-1"
            disabled={disabled}
            label={t('grade-question-other-diseases-options.none')}
            {...register('otherDiseaseNone_bool')}
          />
          <CheckboxField
            className="p-1"
            disabled={disabled}
            label={t('grade-question-other-diseases-options.other-concern-for-glaucoma')}
            {...register('otherDiseaseConcernForGlaucoma_bool')}
          />
          <CheckboxField
            className="p-1"
            disabled={disabled}
            label={t('grade-question-other-diseases-options.other-macular-drusen')}
            {...register('otherDiseaseMacularDrusen_bool')}
          />
          <CheckboxField
            className="p-1"
            disabled={disabled}
            label={t('grade-question-other-diseases-options.other-macular-pigmentary')}
            {...register('otherDiseaseMacularPigmentaryDisturbance_bool')}
          />
          <CheckboxField
            className="p-1"
            label={t('grade-question-other-diseases-options.other-epiretinal')}
            {...register('otherDiseaseEpiretinalMembrane_bool')}
          />
          <CheckboxField
            className="p-1"
            label={t('grade-question-other-diseases-options.other-choroidal')}
            {...register('otherDiseaseChoroidalLesion_bool')}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      {/* OTHER OPTION STRING */}
      {enableOtherCustomString && (
        <InputField
          type="text"
          label={t('grade-question-other-disease-specify')}
          classNameFieldSet="mt-4"
          required
          disabled={disabled}
          {...register('otherDiseaseCustomInput_string')}
        />
      )}

      {/* QUESTION FOLLOW UP */}
      {enableFollowUp && (
        <RadioGroup label={t('grade-question-needs-follow-up')} className="mt-6 mb-2">
          <RadioGroupOption
            label={t('grade-options-referral.now')}
            id="follow-up-none"
            value="0_months"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
          <RadioGroupOption
            label={t('grade-options-referral.return-1-mo')}
            id="follow-up-1month"
            value="1_month"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
          <RadioGroupOption
            label={t('grade-options-referral.return-1-to-3-mo')}
            id="follow-up-1to3months"
            value="1-3_months"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
          <RadioGroupOption
            label={t('grade-options-referral.return-3-to-6-mo')}
            id="follow-up-3to6months"
            value="3-6_months"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
          <RadioGroupOption
            label={t('grade-options-referral.return-6-to-12-mo')}
            id="follow-up-6to12months"
            value="6-12_months"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
          <RadioGroupOption
            label={t('grade-options-referral.return-12-to-24-mo')}
            id="follow-up-12to24months"
            value="12-24_months"
            required
            disabled={disabled}
            {...register('otherDiseaseFollowUp_string')}
          />
        </RadioGroup>
      )}
    </div>
  );
}
