import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';

// navigation
import { router } from './router/root';

export const App = () => {
  return (
    <>
      <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
      <ToastContainer stacked />
    </>
  );
};
