import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faFileMagnifyingGlass,
  faShieldCheck,
} from '@fortawesome/pro-solid-svg-icons';

type RequestStateValueProps = {
  value?: string;
};

export function RequestStateValue(props: RequestStateValueProps) {
  const { value } = props;
  const { t } = useTranslation();

  return value === 'rejected' ? (
    <>
      <FontAwesomeIcon icon={faCircleXmark} className="text-red-600" />
      <span className="m-2">{t('rejected')}</span>
    </>
  ) : value === 'approved' ? (
    <>
      <FontAwesomeIcon icon={faShieldCheck} className="text-green-700" />
      <span className="m-2">{t('approved')}</span>
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faFileMagnifyingGlass} className="text-cyan-600" />
      <span className="m-2">{t('pending')}</span>
    </>
  );
}
