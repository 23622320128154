import type { ReactNode, TableHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { RequestStateValue } from './state-request-value';
import { GradeStateValue } from './state-grade-value';

type TableHeaderBase = {
  key: string;
  label?: string;
  type?: 'value';
  format?: (value: TableValue) => string;
  tooltip?: (value: TableData[number]) => ReactNode;
  action?: ReactNode;
};
type TableHeaderAction<T> = {
  key: string;
  type: 'actions';
  edit: (item: T) => void;
  delete: (item: T) => void;
};
type TableHeaderState = {
  key: string;
  label?: string;
  type: 'request-state' | 'grade-state';
  format?: (value: TableValue) => string;
  tooltip?: (value: TableData[number]) => ReactNode;
  action?: ReactNode;
};

type TableHeader<T> = TableHeaderBase | TableHeaderAction<T> | TableHeaderState;
type TableValue = unknown;
type TableData = Record<string, TableValue>[];

type TableProps<T extends TableData> = TableHTMLAttributes<HTMLTableElement> & {
  headers: TableHeader<T[number]>[];
  data: T;
  bodyClassName?: string;
  onRowClick?: (item: T[number]) => void;
};

export function Table<T extends TableData>(props: TableProps<T>) {
  const { headers, data, onRowClick, bodyClassName = '', className = '', ...rest } = props;

  return (
    <table className={`CustomTable table-auto border-collapse ${className}`} {...rest}>
      <thead className="sticky inset-block-start">
        <tr>
          {headers.map((header) => {
            const key = `table-header-${header.key}`;
            const isActionsColumn = header?.type === 'actions';
            return (
              <th
                key={key}
                className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {!isActionsColumn ? (
                  <>
                    {header.label ?? header.key}
                    {header.action ? <span className="ml-1">{header.action}</span> : null}
                  </>
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={` ${bodyClassName}`}>
        {data.map((row, index) => (
          <tr
            key={`table-row-${index}`}
            className="bg-white"
            onClick={onRowClick ? () => onRowClick(row) : undefined}
            role={onRowClick ? 'button' : undefined}
            tabIndex={onRowClick ? 0 : undefined}
            style={onRowClick ? { cursor: 'pointer' } : undefined}
          >
            {headers.map((header) => {
              const key = `table-row-${index}-${header.key}`;

              if (header.type === 'actions') {
                return (
                  <td key={key}>
                    <button
                      className="text-gray-600 hover:text-gray-900"
                      onClick={() => header.edit(row)}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                      className="text-gray-600 hover:text-gray-900 ml-2"
                      onClick={() => header.delete(row)}
                    >
                      <FontAwesomeIcon icon={faTrashXmark} />
                    </button>
                  </td>
                );
              }

              const value = header.format ? header.format(row[header.key]) : row[header.key];

              const tooltip = header.tooltip ? (
                <Tooltip id={key} place="top" clickable>
                  {header.tooltip(row)}
                </Tooltip>
              ) : null;

              if (header.type === 'request-state') {
                return (
                  <td key={key} data-tooltip-id={key}>
                    <span data-tooltip-id={key}>
                      <RequestStateValue value={value as string} />
                    </span>
                    {tooltip}
                  </td>
                );
              }

              if (header.type === 'grade-state') {
                return (
                  <td key={key} data-tooltip-id={key}>
                    <span data-tooltip-id={key}>
                      <GradeStateValue value={value as number} />
                    </span>
                    {tooltip}
                  </td>
                );
              }

              return (
                <td key={key} className="whitespace-nowrap">
                  <span data-tooltip-id={key}>{String(value ?? '-')}</span>
                  {tooltip}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
