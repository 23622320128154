import { Outlet } from 'react-router-dom';

import { LanguageSwitcher } from '@components/language-switcher';

export function EmptyLayoutAuth() {
  return (
    <>
      <LanguageSwitcher isSplashPage />
      <Outlet />
    </>
  );
}
