import { HTMLAttributes, useMemo } from 'react';
import { RequestStateValue } from './state-request-value';
import { GradeStateValue } from './state-grade-value';

type LabelValueProps = HTMLAttributes<HTMLDivElement> & {
  label: string;
  value?: string | number;
  type?: 'value' | 'request-state' | 'grade-state';
};

export function LabelValue(props: LabelValueProps) {
  const { label, value, className = '', type = 'value', ...rest } = props;

  const renderValue = useMemo(() => {
    if (type === 'request-state') {
      return <RequestStateValue value={value?.toString()} />;
    }
    if (type === 'grade-state') {
      return <GradeStateValue value={value as number} />;
    }
    return value;
  }, [type, value]);

  return (
    <div className={className} {...rest}>
      <label className="text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
        {label}
      </label>
      <p className="font-bold">{renderValue}</p>
    </div>
  );
}
